import { NavLink, useHistory, useParams } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FilePill } from "../../../components/FilePill";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { classNames } from "../../../utils/cn";
import Loading from "../../../components/Loading";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import {
  DataRoomFileUploadStatus,
  DeepSearchesQuery,
  DeepSearchFileStatus,
  DeepSearchQuery,
  DeepSearchStatus,
  FileType,
  UpdateDeepSearchFileInput,
  useAddDeepSearchFileReferenceMutation,
  useCreateDeepSearchFileMutation,
  useCreateDeepSearchMutation,
  useDeepSearchesQuery,
  useDeepSearchQuery,
  useFileVersionDownloadUrlQuery,
  useUpdateDeepSearchFileMutation,
  useUpdateDeepSearchFilesMutation,
  useUpdateDeepSearchMutation,
} from "../../../graphql/generated";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Button } from "../../../components/tailwind/Button";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import useGqlClient from "../../../hooks/useGqlClient";
import { toasts } from "../../../components/toasts/toasts";
import { useCallback, useEffect, useState } from "react";
import { EditableText } from "../../../components/EditableText";
import { Spinner } from "../../../components/icons/Spinner";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from "react";
import { AppState } from "../../../store";
import { deepSearchSelectors } from "../../../store/deep-search/selector";
import { actions } from "../../../store/deep-search/slice";
import { EyeIcon, EyeOffIcon, SearchIcon } from "lucide-react";
import { FileIcon } from "@/src/components/FileIcon";
import { Pspdfkit } from "@/src/components/document_viewers/Pspdfkit";
import {
  DocumentRefsProvider,
  useDocumentRefs,
} from "@/src/hooks/deep-search/useDocumentRefs";
import { PdfViewerHandle } from "@/src/components/PdfViewer";
import PSPDFKit from "pspdfkit";
import { StatusDot } from "@/src/components/StatusDot";
import { AddFileModal } from "@/src/components/AddFileModal";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { reorder } from "@/src/utils/dnd";

export function DeepSearch() {
  const { id } = useParams<{ id: string }>();
  const showSidebar = useSelector((state: AppState) =>
    deepSearchSelectors.showSidebar(state, id)
  );

  return (
    <div className="flex-1 flex flex-col min-w-0">
      <div className="flex-1 flex flex-col h-full w-full min-w-0">
        <div className="lg:hidden flex  items-center justify-center flex-1">
          <p className="font-semibold text-gray-600">Not supported on mobile</p>
        </div>
        <div className="hidden lg:flex flex-1 min-w-0">
          <div className="flex flex-1 relative min-w-0">
            {showSidebar ? <Sidebar /> : null}
            <DeepSearchContent id={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

function DeepSearchContent(props: { id: string | undefined }) {
  if (!props.id) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <p className="text-xs text-gray-500">No search selected</p>
      </div>
    );
  }

  return (
    <DocumentRefsProvider>
      <DeepSearchContentInner id={props.id} />
    </DocumentRefsProvider>
  );
}

function DeepSearchContentInner(props: { id: string }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const deepSearch = useDeepSearchQuery(
    client,
    { id: props.id },
    {
      refetchInterval(query) {
        if (
          query.state.data &&
          query.state.data.deepSearch.status === DeepSearchStatus.Searching
        ) {
          return 1000;
        }

        if (
          query.state.data &&
          query.state.data.deepSearch.files.length > 0 &&
          query.state.data.deepSearch.files.filter(
            (f) =>
              f.status === DeepSearchFileStatus.Searching ||
              f.status === DeepSearchFileStatus.Initial
          ).length > 0
        ) {
          return 1000;
        }

        return false;
      },
    }
  );

  const [files, setFiles] = useState<DeepSearchQuery["deepSearch"]["files"]>(
    []
  );

  useEffect(() => {
    if (deepSearch.data && deepSearch.data.deepSearch.files) {
      setFiles(
        deepSearch.data.deepSearch.files.sort((a, b) => {
          if (a.hidden && !b.hidden) return 1;
          if (!a.hidden && b.hidden) return -1;

          return a.sortIndex - b.sortIndex;
        })
      );
    }
  }, [deepSearch.data]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const updateDeepSearch = useUpdateDeepSearchMutation(client);
  const updateDeepSearchFiles = useUpdateDeepSearchFilesMutation(client);
  const dispatch = useDispatch();
  const showSidebar = useSelector((state: AppState) =>
    deepSearchSelectors.showSidebar(state, props.id)
  );

  function handleUpdateFiles(files: DeepSearchQuery["deepSearch"]["files"]) {
    const inputs: UpdateDeepSearchFileInput[] = files.map((file) => {
      return {
        id: file.id,
        sortIndex: file.sortIndex,
      };
    });

    updateDeepSearchFiles.mutate(
      {
        input: {
          files: inputs,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["DeepSearch", { id: props.id }],
          });
        },
      }
    );
  }

  function handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId !== result.source.droppableId) {
      return;
    }

    const newFiles = reorder(
      files,
      result.source.index,
      result.destination.index
    ).map((f, i) => {
      return {
        ...f,
        sortIndex: i,
      };
    });

    setFiles(newFiles);
    handleUpdateFiles(newFiles);
  }

  if (deepSearch.error) {
    return (
      <div className="flex-1 flex items-center justify-center h-full">
        <p className="text-xs text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (deepSearch.isLoading || !deepSearch.data) {
    return (
      <div className="flex-1 flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex-1 min-w-0">
      <div className="flex-1 px-3 justify-between h-12 bg-white flex items-center border-b border-gray-200">
        {/* <p className="font-semibold text-sm text-gray-700">
          {errorCheck.data.errorCheck.name
            ? errorCheck.data.errorCheck.name
            : "New error check"}
        </p> */}
        <EditableText
          initialText={
            deepSearch.data.deepSearch.name
              ? deepSearch.data.deepSearch.name
              : "New search"
          }
          onSave={(text) => {
            updateDeepSearch.mutate(
              {
                input: {
                  id: props.id,
                  name: text,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearch", { id: props.id }],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearches", { dealId: activeDealId ?? "" }],
                  });
                },
                onError: () => {
                  toasts.error("Failed to update");
                },
              }
            );
          }}
          onCancel={() => {}}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateDeepSearch.mutate(
              {
                input: {
                  id: props.id,
                  query: searchTerm,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearch", { id: props.id }],
                  });
                },
              }
            );
          }}
          className="w-1/3 lg:w-1/2 flex items-center gap-x-2"
        >
          <input
            className="h-8 hidden lg:flex cursor-text items-center w-full border-0 bg-gray-100 rounded-md shadow-sm pl-2 pr-3 text-gray-900 placeholder:text-gray-400  sm:text-sm focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
          <Button
            isLoading={
              deepSearch.data.deepSearch.status === DeepSearchStatus.Searching
            }
            loadingText="Searching..."
            text="Search"
            icon={SearchIcon}
            variant="neutral"
            size="s"
          />
        </form>
        <div className="flex items-center gap-x-2">
          <Button
            size="s"
            variant="neutral"
            text={showSidebar ? "Expand" : "Collapse"}
            icon={showSidebar ? ArrowsPointingOutIcon : ArrowsPointingInIcon}
            onClick={() => {
              dispatch(
                actions.setShowSidebar({
                  deepSearchId: props.id,
                  show: !showSidebar,
                })
              );
            }}
          />
        </div>
      </div>

      <div className="flex-1 flex min-w-0 overflow-hidden">
        <ReferenceSidebar
          deepSearch={deepSearch.data.deepSearch}
          files={files}
        />
        <div className="flex-1 flex min-w-0 flex-col overflow-x-hidden">
          <DragDropContext
            onDragEnd={(result) => {
              handleDragEnd(result);
            }}
          >
            <DeepSearchFiles
              deepSearch={deepSearch.data.deepSearch}
              search={searchTerm}
              files={files}
            />
          </DragDropContext>
        </div>
      </div>
      {/* <SlideOver
        open={showFileDetails}
        onClose={() => setShowFileDetails(false)}
        errorCheck={errorCheck.data.errorCheck}
      /> */}
      {/* <AddFilesModal
        open={showAddFiles}
        onClose={() => setShowAddFiles(false)}
        dealId={activeDealId ?? ""}
        errorCheckId={props.id}
      /> */}
    </div>
  );
}

function ReferenceSidebar(props: {
  deepSearch: DeepSearchQuery["deepSearch"];
  files: DeepSearchQuery["deepSearch"]["files"];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createDeepSearchFile = useCreateDeepSearchFileMutation(client);

  const [openModal, setOpenModal] = useState<"add-document" | "">("");

  return (
    <div
      style={{
        height: "calc(100vh - 200px)",
      }}
      className="overflow-y-auto pb-2 shadow-sm h-full w-[250px] rounded-tr-md rounded-br-md bg-white border-r border-gray-200"
    >
      <div className="flex items-center justify-between pr-2 py-2">
        <p className="px-3 py-2 font-semibold text-sm text-gray-700">
          Documents
        </p>
        <button
          onClick={() => {
            setOpenModal("add-document");
          }}
          className="p-1 rounded-md hover:bg-gray-200"
        >
          <PlusIcon className="w-4 h-4 text-gray-500" />
        </button>
      </div>

      <div className="px-1 space-y-2 ">
        {props.files
          .sort((a, b) => {
            if (a.hidden && !b.hidden) return 1;
            if (!a.hidden && b.hidden) return -1;
            return 0;
          })
          .map((f, i) => {
            return (
              <ReferenceSideBarFile
                key={`${f.file.id}-${i}`}
                id={props.deepSearch.id}
                file={f}
              />
            );
          })}
        <button
          onClick={() => {
            setOpenModal("add-document");
          }}
          className="p-3 hover:shadow-sm border border-gray-200 flex items-center justify-center gap-x-2 rounded-md w-full text-sm text-gray-500 font-semibold hover:border-gray-300 hover:text-gray-700"
        >
          <PlusIcon className="w-4 h-4 text-gray-500" />
          Add more documents
        </button>
      </div>
      <AddFileModal
        onFileSelected={(file) => {
          createDeepSearchFile.mutate(
            {
              input: {
                deepSearchID: props.deepSearch.id,
                dataRoomFileID: file.id,
                dataRoomFileVersionID: file.currentLiveVersion.id,
              },
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ["DeepSearch", { id: props.deepSearch.id }],
                });
              },
            }
          );
        }}
        open={openModal === "add-document"}
        onClose={() => {
          setOpenModal("");
        }}
      />
    </div>
  );
}

function ReferenceSideBarFile(props: {
  id: string;
  file: DeepSearchQuery["deepSearch"]["files"][0];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDeepSearchFile = useUpdateDeepSearchFileMutation(client);
  const { refs } = useDocumentRefs();

  const groupedRefs = props.file.references.reduce((acc, ref) => {
    const pageIndex = ref.pageIndex;
    if (!acc[pageIndex]) {
      acc[pageIndex] = [];
    }
    acc[pageIndex].push(ref);
    return acc;
  }, {} as Record<number, typeof props.file.references>);

  const sortedPageIndexes = Object.keys(groupedRefs)
    .map(Number)
    .sort((a, b) => a - b);

  return (
    <div className="" key={props.file.id}>
      <div
        className={classNames(
          "bg-gray-50 shadow-sm border border-gray-200 rounded-md flex flex-col items-start",
          props.file.hidden ? "opacity-50 hover:opacity-100" : ""
        )}
      >
        <div className="bg-white flex border-b w-full rounded-t-md border-gray-200 p-2 items-center justify-between gap-x-2">
          <div className="flex items-center gap-x-2">
            <FileIcon fileType={props.file.file.fileType} />
            <div>
              <p className="font-semibold text-sm text-gray-700 line-clamp-1 hover:line-clamp-none text-ellipsis">
                {props.file.file.name}
              </p>
              <div className="flex items-center gap-x-1">
                <StatusDot
                  status={
                    props.file.file.currentLiveVersion.id ===
                    props.file.currentVersion.id
                      ? "positive"
                      : "neutral"
                  }
                />
                <p className="text-xs text-gray-600">
                  Version {props.file.currentVersion.versionNumber}
                </p>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              updateDeepSearchFile.mutate(
                {
                  input: { id: props.file.id, hidden: !props.file.hidden },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["DeepSearch", { id: props.id }],
                    });
                  },
                }
              );
            }}
            className="p-1 rounded-md hover:bg-gray-200"
          >
            {props.file.hidden ? (
              <EyeOffIcon className="w-4 h-4 text-gray-500" />
            ) : (
              <EyeIcon className="w-4 h-4 text-gray-500" />
            )}
          </button>
        </div>

        {props.file.status === DeepSearchFileStatus.Searching ? (
          <div className="flex items-center justify-center p-2">
            <Spinner color="gray" size="s" />
            <p className=" text-sm text-gray-500">
              Searching for references...
            </p>
          </div>
        ) : null}

        {props.file.status === DeepSearchFileStatus.Complete &&
        props.file.references.length === 0 ? (
          <div className="flex items-center justify-center p-2">
            <p className="text-sm text-gray-500">No references found</p>
          </div>
        ) : null}

        {props.file.status === DeepSearchFileStatus.Complete &&
        props.file.references.length > 0 ? (
          <div className="mt-2 space-y-2 p-2 w-full">
            {sortedPageIndexes.map((pageIndex) => {
              return (
                <ReferenceSideBarFilePageReference
                  key={`${pageIndex}-${props.file.id}`}
                  pageIndex={pageIndex}
                  references={groupedRefs[pageIndex]}
                  fileId={props.file.file.id}
                  refs={refs}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function ReferenceSideBarFilePageReference(props: {
  pageIndex: number;
  references: Array<{
    pageIndex: number;
    quote: string;
    rectsOnPage: number[][];
  }>;
  fileId: string;
  refs: Record<string, PdfViewerHandle>;
}) {
  const [expanded, setExpanded] = useState(true);
  console.log("rerendering");

  return (
    <div className="">
      <div className="flex items-center justify-between mb-2 flex-1">
        <p className="text-sm text-gray-500 font-semibold">
          Page {props.pageIndex + 1}{" "}
          <span className="text-gray-400 font-normal">
            ({props.references.length}{" "}
            {props.references.length === 1 ? "reference" : "references"})
          </span>
        </p>
        <button
          onClick={() => setExpanded(!expanded)}
          className="text-gray-500 hover:text-gray-700"
        >
          {expanded ? "−" : "+"}
        </button>
      </div>

      {expanded && (
        <div className="space-y-2">
          {props.references.map((r, i) => (
            <div
              onClick={(e) => {
                const element = document.getElementById(`doc-${props.fileId}`);
                if (element) {
                  const container = element.parentElement;
                  if (container) {
                    container.scrollTo({
                      left: element.offsetLeft - container.offsetLeft,
                      behavior: "smooth",
                    });
                  }
                }
                const ref = props.refs[props.fileId];
                if (!ref) {
                  return;
                }

                const rect = new PSPDFKit.Geometry.Rect({
                  top: r.rectsOnPage[0][1],
                  left: r.rectsOnPage[0][0],
                  width: r.rectsOnPage[0][2],
                  height: r.rectsOnPage[0][3],
                });

                ref.jumpToRect(r.pageIndex, rect);
              }}
              key={`${r.pageIndex}-${r.rectsOnPage}-${i}`}
              className="p-2 rounded-md bg-white border border-gray-200 cursor-pointer hover:shadow-md hover:border-gray-300"
            >
              <p className="text-xs text-gray-700 font-medium line-clamp-3">
                {r.quote}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function DeepSearchFiles(props: {
  deepSearch: DeepSearchQuery["deepSearch"];
  search: string;
  files: DeepSearchQuery["deepSearch"]["files"];
}) {
  return (
    <Droppable direction="horizontal" droppableId={`${props.deepSearch.id}`}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <div className="flex-1 min-w-0">
            <div className="flex overflow-x-auto whitespace-nowrap space-x-4 p-4">
              {props.files
                .filter((file) => !file.hidden)
                .sort((a, b) => {
                  return a.sortIndex - b.sortIndex;
                })
                .map((file, i) => (
                  <div key={`${file.file.id}-${i}`}>
                    <Draggable
                      key={`${file.file.id}-${i}`}
                      draggableId={`${file.id}-${i}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={classNames(
                            "flex-none rounded-md shadow-sm py-2 bg-white",
                            snapshot.isDragging
                              ? "opacity-80 border-2 border-blue-400"
                              : ""
                          )}
                        >
                          <div {...provided.dragHandleProps}>
                            <DeepSearchFileHeader file={file} />
                          </div>
                          <FileViewWrapper
                            deepSearchId={props.deepSearch.id}
                            file={file}
                            search={props.search}
                          />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function DeepSearchFileHeader(props: {
  file: DeepSearchQuery["deepSearch"]["files"][0];
}) {
  return (
    <div className="flex items-center gap-x-2 p-2 border-b border-gray-200">
      <FileIcon fileType={props.file.file.fileType} />
      <p className="font-semibold text-sm text-gray-700">
        {props.file.file.name}
      </p>
    </div>
  );
}

export function FileViewWrapper(props: {
  file: DeepSearchQuery["deepSearch"]["files"][0];
  deepSearchId: string;
  search: string;
}) {
  const { setRef } = useDocumentRefs();
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const addDeepSearchFileReference =
    useAddDeepSearchFileReferenceMutation(client);

  const callbackRef = useCallback(
    (node: PdfViewerHandle | null) => {
      if (node) {
        setRef(props.file.file.id, node);
      }
    },
    [setRef, props.file.file.id]
  );

  if (
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (
    props.file.file.fileType === FileType.Pdf ||
    props.file.file.fileType === FileType.Docx ||
    props.file.file.fileType === FileType.Pptx
  ) {
    return (
      <Pspdfkit
        mode="dataRoom"
        dataRoomFileVersionId={props.file.currentVersion.id}
        search={props.search}
        ref={callbackRef}
        onAnnotationCreated={(annotation) => {
          addDeepSearchFileReference.mutate(
            {
              input: {
                deepSearchFileID: props.file.id,
                pageIndex: annotation.pageIndex,
                quote: annotation.text,
                rectsOnPage: annotation.rects,
              },
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ["DeepSearch", { id: props.deepSearchId }],
                });
              },
            }
          );
        }}
        rectsOnPages={props.file.references.map((r) => ({
          pageIndex: r.pageIndex,
          rects: r.rectsOnPage,
        }))}
      />
    );
  }

  return <FileViewContent file={props.file} />;
}

function FileViewContent(props: {
  file: DeepSearchQuery["deepSearch"]["files"][0];
}) {
  const client = useGqlClient();
  const [url, setUrl] = React.useState<string>("");
  const [fileDownloadError, setFileDownloadError] = React.useState<string>("");

  const fileDownloadUrl = useFileVersionDownloadUrlQuery(
    client,
    {
      id: props.file.currentVersion.id,
    },
    {
      refetchOnWindowFocus(query) {
        if (query.isStaleByTime(1000 * 60 * 5)) {
          return true;
        }

        return false;
      },
    }
  );

  useEffect(() => {
    if (
      fileDownloadUrl.data &&
      fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl !== url
    ) {
      setFileDownloadError("");
      setUrl(fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl);
      return;
    }

    if (fileDownloadUrl.error) {
      setFileDownloadError("Unable to load file");
      return;
    }
  }, [
    fileDownloadUrl.data,
    fileDownloadUrl.isRefetching,
    fileDownloadUrl.isPending,
    fileDownloadUrl.error,
    url,
  ]);

  if (
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (url === "") {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadError) {
    return (
      <div className="flex items-center justify-center">
        <p>Failed to load file</p>
        <Button
          variant="neutral"
          text="Retry"
          isLoading={fileDownloadUrl.isPending || fileDownloadUrl.isRefetching}
          loadingText="Retrying..."
          onClick={() => {
            fileDownloadUrl.refetch();
          }}
        />
      </div>
    );
  }

  return (
    <DocViewer
      key={url}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: 800, width: "100%", overflowY: "scroll" }}
      documents={[
        {
          uri: url,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}
function Sidebar() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const createDeepSearch = useCreateDeepSearchMutation(client);

  const deepSearches = useDeepSearchesQuery(client, {
    dealId: activeDealId ?? "",
  });

  useEffect(() => {
    if (id) {
      return;
    }

    if (deepSearches.data && deepSearches.data.deepSearches.length > 0) {
      history.push(
        `/deal/toolkit/deep-search/${deepSearches.data.deepSearches[0].id}`
      );
    }
  }, [deepSearches.data, id]);
  console.log("test");

  function createDeepSearchFn() {
    if (!activeDealId) return;
    createDeepSearch.mutate(
      {
        input: {
          dataRoomFileIDs: [],
          dealID: activeDealId ?? "",
        },
      },
      {
        onSuccess: (dat) => {
          queryClient.invalidateQueries({
            queryKey: ["DeepSearches", { dealId: activeDealId ?? "" }],
          });
          history.push(`/deal/toolkit/deep-search/${dat.createDeepSearch.id}`);
        },
        onError: () => {
          toasts.error("Failed to create document search & comparison");
        },
      }
    );
  }

  return (
    <div className="w-72 bg-white border-r border-gray-200 border-b overflow-x-hidden">
      <div className="flex justify-between items-center p-3 py-2.5 ">
        <p className="font-semibold text-sm text-gray-600">Recent searches</p>
        <button
          onClick={() => {
            createDeepSearchFn();
          }}
          className="flex items-center p-0.5 border rounded-md border-gray-300 hover:border-gray-500 hover:bg-gray-50"
        >
          <PlusIcon className="h-5 w-5 " />
        </button>
      </div>
      <SidebarList
        query={deepSearches}
        onCreate={() => {
          createDeepSearchFn();
        }}
      />
    </div>
  );
}

function SidebarList(props: {
  query: UseQueryResult<DeepSearchesQuery, unknown>;
  onCreate: () => void;
}) {
  if (props.query.error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-xs text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  if (props.query.data.deepSearches.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div>
          <p className=" text-center text-sm text-gray-500">No searches</p>
          <Button
            onClick={props.onCreate}
            margin="s 0 0 0"
            icon={PlusIcon}
            variant="neutral"
            text="New search"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "calc(100vh)",
      }}
      className="overflow-y-scroll overflow-x-hidden"
    >
      <TransitionGroup>
        {props.query.data.deepSearches.map((ds) => {
          return (
            <CSSTransition
              key={ds.id}
              timeout={300} // This controls the duration of the animation
              classNames="fade-slide-down"
              onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
            >
              <NavLink
                key={ds.id}
                to={`/deal/toolkit/deep-search/${ds.id}`}
                className={(isActive) => {
                  return classNames(
                    "px-3 py-3 hover:bg-gray-100 font-semibold text-gray-600 flex items-center"
                  );
                }}
                activeClassName="bg-gray-100 text-gray-500"
              >
                <div className="cursor-pointer ">
                  <p className="text-sm text-gray-500 font-semibold">
                    {ds.name ? ds.name : "New search"}
                  </p>
                  <p className="text-xs text-gray-500 font-light">
                    {formatDistanceToNowStrict(fromUnixTime(ds.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                  <div className="overflow-x-scroll flex flex-wrap gap-2 mt-2 w-72 scrollbar-none">
                    {ds.files.map((file, i) => {
                      return (
                        <FilePill
                          key={`${file.file.id}-${i}`}
                          id={file.file.id}
                          name={file.file.name}
                          type={file.file.fileType}
                          showDetailsCard={false}
                        />
                      );
                    })}
                  </div>
                </div>
              </NavLink>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}

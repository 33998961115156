import { classNames } from "./cn";

export const renderMentions = (text: string, mentionColor?: string) => {
  const parts = text.split(/(@\[.*?\]\(.*?\))/g); // Split by mention pattern
  return parts.map((part, index) => {
    const match = part.match(/\[(.*?)\]\((.*?)\)/);
    if (match) {
      const [_, displayName, userId] = match; // Extract name and ID
      return (
        <span
          key={index}
          className={classNames(
            "font-semibold",
            mentionColor ? mentionColor : "text-persian-600"
          )}
        >
          {displayName}
        </span>
      );
    }
    return part; // Non-mention text remains as is
  });
};

import { useUnreadNotificationCountQuery } from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { classNames } from "@/src/utils/cn";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export function NotificationDot() {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const unreadNotificationCount = useUnreadNotificationCountQuery(
    client,
    {},
    {
      refetchOnWindowFocus: true,
      refetchInterval: 2000,
    }
  );
  const [previousCount, setPreviousCount] = useState(0);

  useEffect(() => {
    if (
      unreadNotificationCount.data &&
      previousCount !==
        unreadNotificationCount.data.account.unreadNotificationCount
    ) {
      queryClient.invalidateQueries({ queryKey: ["Notifications"] });
      setPreviousCount(
        unreadNotificationCount.data.account.unreadNotificationCount
      );
    }
  }, [unreadNotificationCount.data, previousCount, queryClient]);

  if (
    !unreadNotificationCount.data ||
    unreadNotificationCount.data.account.unreadNotificationCount === 0
  ) {
    return null;
  }

  return (
    <div className="absolute -top-1.5 -right-1.5 m-[1px] w-4 h-4 bg-blue-500 rounded-full flex items-center justify-center">
      <p
        className={classNames(
          "text-white font-medium text-sm leading-none",
          unreadNotificationCount.data.account.unreadNotificationCount > 9
            ? "text-xs"
            : ""
        )}
      >
        {unreadNotificationCount.data.account.unreadNotificationCount}
      </p>
    </div>
  );
}

// Used for viewing pdfs, docx and pptx files
import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import PSPDFKit, { Instance, ToolbarItem, Rect } from "pspdfkit";
import { pspdfkitEndpoint } from "../../utils/endpoints";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useDataRoomFileVersionQuery } from "@/src/graphql/generated";
import Loading from "../Loading";
import { Button } from "../tailwind/Button";
import { hashRectsOnPage } from "./utils";

export interface PdfViewerHandle {
  setPageIndex: (pageIndex: number) => void;
  getCurrentPage: () => number;
  jumpToRect: (pageIndex: number, rect: Rect) => void;

  // Add other methods you want to expose
}

type DirectRenderProps = {
  mode: "direct";
  documentId: string;
  token: string;
};

type DataRoomProps = {
  mode: "dataRoom";
  dataRoomFileVersionId: string;
};

type CommonProps = {
  highlights?: string[];
  pageIndex?: number;
  rectsOnPage?: number[][];
  rectsOnPages?: { pageIndex: number; rects: number[][] }[];
  showToolbar?: boolean;
  search?: string;
  width?: number;
  height?: number;
  onAnnotationCreated?: (annotation: {
    text: string;
    pageIndex: number;
    rects: number[][];
  }) => void;
};

type PspdfkitProps = CommonProps & (DirectRenderProps | DataRoomProps);

export const Pspdfkit = forwardRef<PdfViewerHandle, PspdfkitProps>(
  function Pspdfkit(props, ref) {
    if (props.mode === "dataRoom") {
      return <DataRoomFileRenderer {...props} ref={ref} />;
    }

    return <PspdfkitRenderer {...props} ref={ref} />;
  }
);

type PspdfkitRendererProps = CommonProps & DirectRenderProps;

const PspdfkitRenderer = forwardRef<PdfViewerHandle, PspdfkitRendererProps>(
  function PspdfkitRenderer(props, ref) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [instance, setInstance] = useState<Instance | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        setPageIndex: (pageIndex: number) => {
          if (instance && pageIndex < instance.totalPageCount) {
            instance.setViewState(
              instance.viewState.set("currentPageIndex", pageIndex)
            );
          }
        },

        getCurrentPage: () => {
          return instance ? instance.viewState.currentPageIndex : 0;
        },
        jumpToRect(pageIndex, rect) {
          if (instance) {
            instance.jumpToRect(pageIndex, rect);
          }
        },

        // Add other methods as needed
      }),
      [instance]
    );

    useEffect(() => {
      const container = containerRef.current; // This `useRef` instance will render the PDF.
      if (!container) {
        return;
      }

      const toolbarItems: ToolbarItem[] = [
        {
          type: "sidebar-thumbnails",
        },
        {
          type: "pager",
        },
        {
          type: "spacer",
        },
        {
          type: "zoom-in",
        },
        {
          type: "zoom-out",
        },
        {
          type: "text-highlighter",
        },
        {
          type: "search",
        },
      ];

      (async function () {
        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

        const i = await PSPDFKit.load({
          // Container where PSPDFKit should be mounted.
          container,
          autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
          // The document to open.
          documentId: props.documentId,
          authPayload: {
            jwt: props.token,
          },
          instant: false,
          serverUrl: pspdfkitEndpoint(),
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          toolbarItems: toolbarItems,
          // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        });

        setInstance(i);

        if (props.rectsOnPage && props.rectsOnPage.length > 0 && i) {
          const rects = props.rectsOnPage.map((rect) => {
            return new PSPDFKit.Geometry.Rect({
              left: rect[0],
              top: rect[1],
              width: rect[2],
              height: rect[3],
            });
          });

          const rcts = PSPDFKit.Immutable.List(rects);

          const a = new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex: props.pageIndex || 0,
            rects: rcts,
            boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
            color: PSPDFKit.Color.LIGHT_YELLOW,
          });

          i.create(a);
        }

        if (props.rectsOnPages && props.rectsOnPages.length > 0) {
          for (const { pageIndex, rects: rectsOnPage } of props.rectsOnPages) {
            const rects = rectsOnPage.map((r) => {
              return new PSPDFKit.Geometry.Rect({
                left: r[0],
                top: r[1],
                width: r[2],
                height: r[3],
              });
            });
            const rcts = PSPDFKit.Immutable.List(rects);
            const a = new PSPDFKit.Annotations.HighlightAnnotation({
              pageIndex,
              rects: rcts,
              boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
              color: PSPDFKit.Color.LIGHT_YELLOW,
              isEditable: false,
              isDeletable: false,
            });

            i.create(a);
          }
        }

        if (props.highlights && i) {
          let annotations = [];
          for (const h of props.highlights) {
            if (!h) {
              continue;
            }
            const results = await i.search(h);

            const a = results.map((result) => {
              return new PSPDFKit.Annotations.HighlightAnnotation({
                pageIndex: result.pageIndex,
                rects: result.rectsOnPage,
                boundingBox: PSPDFKit.Geometry.Rect.union(result.rectsOnPage),
              });
            });

            annotations.push(...a);
          }

          for (const a of annotations) {
            i.create(a);
          }
        }

        if (props.showToolbar === false) {
          i.setViewState(i.viewState.set("showToolbar", props.showToolbar));
        }

        if (props.pageIndex) {
          if (
            !Number.isNaN(props.pageIndex) &&
            props.pageIndex < i.totalPageCount
          ) {
            i.setViewState(
              i.viewState.set("currentPageIndex", props.pageIndex)
            );
          }
        }

        i.addEventListener("annotations.create", (createdAnnotations) => {
          for (const ca of createdAnnotations) {
            if (ca) {
              i.getTextFromRects(ca.pageIndex, ca.rects)
                .then((v) => {
                  const rects = ca.rects.map((r: Rect) => {
                    return [r.left, r.top, r.width, r.height];
                  });

                  if (props.rectsOnPages) {
                    for (const existing of props.rectsOnPages) {
                      if (
                        existing.pageIndex === ca.pageIndex &&
                        hashRectsOnPage(existing.rects) ===
                          hashRectsOnPage(rects)
                      ) {
                        return;
                      }
                    }
                  }

                  if (props.onAnnotationCreated) {
                    props.onAnnotationCreated({
                      text: v,
                      pageIndex: ca.pageIndex,
                      rects: ca.rects.map((r: Rect) => {
                        return [r.left, r.top, r.width, r.height];
                      }),
                    });
                  }
                })
                .catch((e) => {
                  console.log("error", e);
                });
            }
          }
        });
      })();

      return () => {
        PSPDFKit.unload(container);
      };
    }, [props.documentId]);

    useEffect(() => {
      async function addRectsOnPages() {
        if (!instance || !props.rectsOnPages || !props.documentId) {
          return;
        }

        for (const { pageIndex, rects: rectsOnPage } of props.rectsOnPages) {
          const existingAnnotations = await instance.getAnnotations(pageIndex);

          const hasExisting =
            existingAnnotations.filter(
              (h) =>
                hashRectsOnPage(
                  h.rects.map((r: Rect) => {
                    return [r.left, r.top, r.width, r.height];
                  })
                ) === hashRectsOnPage(rectsOnPage)
            ).size > 0;

          if (hasExisting) {
            continue;
          }

          const rects = rectsOnPage.map((r) => {
            return new PSPDFKit.Geometry.Rect({
              left: r[0],
              top: r[1],
              width: r[2],
              height: r[3],
            });
          });
          const rcts = PSPDFKit.Immutable.List(rects);
          const a = new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex,
            rects: rcts,
            boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
            color: PSPDFKit.Color.LIGHT_YELLOW,
            isEditable: false,
            isDeletable: false,
          });

          instance.create(a);
        }
      }

      addRectsOnPages();
    }, [props.rectsOnPages, instance, props.documentId]);

    useEffect(() => {
      if (!instance || !props.search) {
        return;
      }

      async function addAnnotions() {
        const i = instance;
        let annotations = [];
        if (!props.search || !i || props.search.length < 3) {
          return;
        }

        const results = await i.search(props.search);

        const a = results.map((result) => {
          return new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex: result.pageIndex,
            rects: result.rectsOnPage,
            boundingBox: PSPDFKit.Geometry.Rect.union(result.rectsOnPage),
          });
        });

        annotations.push(...a);

        for (const a of annotations) {
          i.create(a);
        }
      }
      addAnnotions();
    }, [props.search, instance]);

    return (
      <div
        style={{
          width: props.width || 600,
          height: props.height || "calc(100vh - 200px)",
        }}
      >
        <div ref={containerRef} style={{ width: "100%", height: "100%" }} />
      </div>
    );
  }
);

type DataRoomFileRendererProps = CommonProps & DataRoomProps;

const DataRoomFileRenderer = forwardRef<
  PdfViewerHandle,
  DataRoomFileRendererProps
>(function DataRoomFileRenderer(props, ref) {
  const client = useGqlClient();

  const dataRoomFileVersionQuery = useDataRoomFileVersionQuery(client, {
    id: props.dataRoomFileVersionId,
  });

  if (dataRoomFileVersionQuery.error) {
    return (
      <div>
        <p className="text-sm font-semibold">
          Error loading file. Please try again.
        </p>
        <Button
          text="Try again"
          size="s"
          variant="neutral"
          onClick={() => {
            dataRoomFileVersionQuery.refetch();
          }}
        />
      </div>
    );
  }

  if (dataRoomFileVersionQuery.isLoading || !dataRoomFileVersionQuery.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const { dataRoomFileVersion } = dataRoomFileVersionQuery.data;

  return (
    <PspdfkitRenderer
      {...props}
      mode="direct"
      documentId={dataRoomFileVersion.pspdfkitDocumentId}
      token={dataRoomFileVersion.pspdfkitToken}
      ref={ref}
    />
  );
});

import { useState } from "react";
import { classNames } from "../../../../../utils/cn";
import {
  DataRoomFileFragmentFragment,
  Question as GqlQuestion,
  DealActivityType,
  FileVersionTextChange,
} from "../../../../../graphql/generated";
import {
  formatDistance,
  formatDistanceToNow,
  formatISO,
  fromUnixTime,
  secondsToMilliseconds,
} from "date-fns";
import { FilePill } from "../../../../../components/FilePill";
import { DeleteFile } from "../../../../DataRoom/DeleteFile";
import { Link, useHistory } from "react-router-dom";
import { EditFile } from "../../../../../components/data_room/EditFile";
import { Pills } from "../../../../../components/Pills";
import { Card } from "../../../../../components/Card";
import { Avatar } from "../../../../../components/account/Avatar";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { Activity } from "../../../../../components/activity/Activity";
import { useDispatch, useSelector } from "react-redux";
import { DetailedFileHistoryModal } from "../../../../../components/data_room/DetailedFileHistoryModal";
import { TextDiffs } from "../../../../../components/tailwind/TextDiffs";
import { dataRoomFileSelectors } from "../../../../../store/dataRoomFile/selector";
import { AppState } from "../../../../../store";
import { actions } from "../../../../../store/dataRoomFile/slice";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Dropdown, { Option } from "../../../../../components/tailwind/Dropdown";
import { useQueryClient } from "@tanstack/react-query";
import { ActivityItems } from "@/src/components/activity/ActivityItems";
import { CommentInput } from "@/src/components/activity/CommentInput";

const tabs = ["Overview", "Comments", "History", "Settings"];

interface Props {
  dataRoomFile: DataRoomFileFragmentFragment;
  type: "seller" | "buyer";
}

export function Tabs(props: Props) {
  return <TabContent dataRoomFile={props.dataRoomFile} tabs={tabs} />;
}

function TabContent(props: {
  dataRoomFile: DataRoomFileFragmentFragment;
  tabs: string[];
}) {
  const [selectedTab, setSelectedTab] = useState(props.tabs[0]);

  const tabs = props.dataRoomFile.deletedAt ? [] : props.tabs;

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={selectedTab}
          onChange={(e) => {
            setSelectedTab(e.currentTarget.value);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              onClick={() => {
                setSelectedTab(tab);
              }}
              key={tab}
              className={classNames(
                tab === selectedTab
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
              aria-current={tab === selectedTab ? "page" : undefined}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-3">
        <TabRenderer selectedTab={selectedTab} file={props.dataRoomFile} />
      </div>
    </>
  );
}

function TabRenderer(props: {
  selectedTab: string;
  file: DataRoomFileFragmentFragment;
}) {
  switch (props.selectedTab) {
    case "Overview":
      return <Overview file={props.file} />;
    case "History":
      return <Versions file={props.file} />;
    case "Comments":
      return <Comments file={props.file} />;
    case "Settings":
      return <Settings file={props.file} />;
    case "Questions":
      return <Questions file={props.file} />;
    default:
      return <Overview file={props.file} />;
  }
}

const stats = ["Versions"];

function Overview(props: { file: DataRoomFileFragmentFragment }) {
  return (
    <div>
      <dl className="mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                {item === "Views"
                  ? props.file.stats.views
                  : item === "Downloads"
                  ? props.file.stats.downloads
                  : props.file.versions.length}
              </div>
            </dd>
          </div>
        ))}
      </dl>

      <div className="mt-8">
        <p className="font-semibold text-gray-700">Activity</p>
        <Activity
          input={{
            dataRoomFileID: props.file.id,
            count: 30,
          }}
          mode="verbose"
        />
      </div>
    </div>
  );
}

function Versions(props: { file: DataRoomFileFragmentFragment }) {
  const [showFullHistory, setShowFullHistory] = useState(false);
  return (
    <div className="mt-2">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-gray-700 text-sm ml-1.5">Versions</p>
      </div>
      <ul role="list" className="mt-3 space-y-6">
        <TransitionGroup className="space-y-6">
          {props.file.versions.map((version, i) => {
            return (
              <CSSTransition
                key={version.id}
                timeout={300} // This controls the duration of the animation
                classNames="fade-slide-down"
                onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
              >
                <li key={version.id}>
                  <Version
                    fileId={props.file.id}
                    version={version}
                    isFirst={i === 0}
                    isLast={i === props.file.versions.length - 1}
                  />
                </li>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </ul>
      <DetailedFileHistoryModal
        dataRoomFileId={props.file.id}
        open={showFullHistory}
        onClose={() => {
          setShowFullHistory(false);
        }}
      />
    </div>
  );
}

function Version(props: {
  fileId: string;
  version: DataRoomFileFragmentFragment["versions"][0];
  isLast: boolean;
  isFirst: boolean;
}) {
  const version = props.version;
  const dispatch = useDispatch();
  const selectedFileVersionId = useSelector((state: AppState) =>
    dataRoomFileSelectors.selectedFileVersionId(state, props.fileId)
  );

  const isSelectedVersion = selectedFileVersionId === version.id;

  return (
    <div
      className={classNames(
        "relative flex gap-x-4 transition-all duration-300 ease-in-out",
        isSelectedVersion ? "" : " opacity-60 cursor-pointer hover:opacity-90"
      )}
      onClick={() => {
        if (isSelectedVersion) {
          return;
        }

        dispatch(
          actions.setSelectedFileVersionId({
            fileId: props.fileId,
            versionId: version.id,
          })
        );
      }}
    >
      <div
        className={classNames(
          props.isLast ? "h-6" : "-bottom-6",
          "absolute left-0 top-0 flex w-6 justify-center"
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <>
        {props.isFirst ? (
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-concrete-50">
            <div
              className={classNames(
                "bg-green-400/10 text-green-400 animate-pulse",
                "flex-none rounded-full p-1"
              )}
            >
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
          </div>
        ) : (
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-concrete-50">
            <div
              className={classNames(
                "h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"
              )}
            />
          </div>
        )}

        <div className="flex-auto rounded-md space-y-2 p-3 pt-1.5 ring-1 ring-inset ring-gray-200">
          <div className="flex justify-between gap-x-4 -py-0.5">
            <p className=" text-xs leading-5 text-gray-500">
              <span className="font-medium text-gray-900">
                {version.createdBy.name}
              </span>{" "}
              uploaded
            </p>
            <time
              title={formatISO(secondsToMilliseconds(version.createdAt))}
              dateTime={formatISO(secondsToMilliseconds(version.createdAt))}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(version.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
          {version.summary ? (
            <>
              <p className="text-xs font-semibold text-gray-600">Summary</p>
              <p className="text-xs leading-2 text-gray-500">
                {version.summary}
              </p>
            </>
          ) : null}
          <p className="text-xs leading-tight font-semibold text-gray-600">
            Changes
          </p>
          <p className="text-xs leading-tight text-gray-500">
            {version.changes.summary}
          </p>
          <div
            className={classNames(
              isSelectedVersion ? "visible" : "invisible h-0"
            )}
          >
            <div className="my-3">
              {version.changes.textChanges.map((tc) => {
                return (
                  <div key={tc.section} className="mt-2">
                    <VersionTextChanges textChange={tc} />
                  </div>
                );
              })}
            </div>
          </div>

          <Pills>
            <FilePill
              id={props.fileId}
              name={version.fileName}
              type={version.fileType}
            />
          </Pills>
        </div>
      </>
    </div>
  );
}

const versionTextChangeOptions: Option[] = [
  {
    label: "Compare",
    value: "compare",
  },
  {
    label: "New",
    value: "new",
  },
  {
    label: "Previous",
    value: "previous",
  },
];

function VersionTextChanges(props: { textChange: FileVersionTextChange }) {
  const [selectedOption, setSelectedOption] = useState<Option>(
    versionTextChangeOptions[0]
  );
  return (
    <div>
      <div className="bg-white rounded-md p-2 border border-gray-200">
        <div className="flex items-center justify-between mb-1">
          <p className="font-semibold text-gray-600 text-xs">
            {props.textChange.section}
          </p>
          <Dropdown
            variant="text"
            size="xs"
            options={versionTextChangeOptions}
            selectedOption={selectedOption}
            onSelect={(o) => {
              setSelectedOption(o);
            }}
          />
        </div>
        {selectedOption.value === "new" ? (
          <p className="text-xs  text-gray-500">{props.textChange.new}</p>
        ) : null}
        {selectedOption.value === "previous" ? (
          <p className="text-xs  text-gray-500">{props.textChange.previous}</p>
        ) : null}
        {selectedOption.value === "compare" ? (
          <TextDiffs diffs={props.textChange.diffs} />
        ) : null}
      </div>
    </div>
  );
}

function Comments(props: { file: DataRoomFileFragmentFragment }) {
  const queryClient = useQueryClient();
  const comments = props.file.activity
    .filter((a) => a.type === DealActivityType.Comment)
    .sort((a, b) => a.createdAt - b.createdAt);
  return (
    <div>
      <div className="mt-4 px-3">
        <ActivityItems items={comments} />

        <CommentInput
          input={{
            dataRoomFileId: props.file.id,
            comment: "",
            files: [],
          }}
          onCommented={() => {
            queryClient.invalidateQueries({
              queryKey: ["DataRoomFileView", { id: props.file.id }],
            });
          }}
        />
      </div>
    </div>
  );
}

function Settings(props: { file: DataRoomFileFragmentFragment }) {
  const history = useHistory();
  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [showEditFile, setShowEditFile] = useState(false);

  return (
    <div className="p-3">
      <div className="grid grid-cols-1 gap-y-4 xl:gap-y-0 xl:grid-cols-2">
        <div>
          <p className="mt-2 text-sm font-semibold text-gray-600">
            Description
          </p>
          <div className="mt-1 flex flex-wrap">
            <p className="text-sm text-gray-500/80">
              {props.file.description
                ? props.file.description
                : "No file description"}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 ">
        <div className="w-full h-px bg-gray-200 my-4"></div>
        <button
          onClick={() => {
            setShowEditFile(true);
          }}
          className="text-blue-700 text-sm px-2 py-1.5 rounded-md hover:bg-blue-50 font-semibold"
        >
          Edit file
        </button>
        <button
          onClick={() => {
            setShowDeleteFile(true);
          }}
          className="text-red-700 text-sm ml-4 px-2 py-1.5 rounded-md hover:bg-red-50 font-semibold"
        >
          Delete file
        </button>
      </div>
      <DeleteFile
        open={showDeleteFile}
        dataRoomFileId={props.file.id}
        onDeleted={() => {
          history.push(`/deal/documents/`);
        }}
        onClose={() => {
          setShowDeleteFile(false);
        }}
      />
      <EditFile
        dataRoomFileId={props.file.id}
        open={showEditFile}
        onClose={() => {
          setShowEditFile(false);
        }}
      />
    </div>
  );
}

function Questions(props: { file: DataRoomFileFragmentFragment }) {
  return (
    <div>
      {props.file.questions.length === 0 ? (
        <p className="px-3 text-gray-500/80 text-sm">No questions</p>
      ) : (
        <Card margin="s 0 0 0">
          {props.file.questions.map((question, i) => {
            return (
              <>
                <Question
                  key={question.id}
                  question={question as GqlQuestion}
                  isFirst={i === 0}
                  isLast={i === props.file.questions.length - 1}
                />
                {i !== props.file.questions.length - 1 ? (
                  <div className="border-b border-gray-200" />
                ) : null}
              </>
            );
          })}
        </Card>
      )}
    </div>
  );
}

interface QuestionProps {
  question: GqlQuestion;
  isFirst: boolean;
  isLast: boolean;
}

function Question(props: QuestionProps) {
  return (
    <div
      key={props.question.id}
      className={`w-full 3xl:w-4/5 hover:bg-gray-200/70 cursor-pointer ${
        props.isFirst ? "rounded-t-md" : ""
      } ${props.isLast ? "rounded-b-md" : ""}`}
    >
      <Link to={`/deal/questions/${props.question.id}`}>
        <div className="flex px-3 py-2 items-center space-x-3">
          <Avatar account={props.question.createdBy} />
          <div className="flex-1">
            <p className="text-gray-700 font-bold">{props.question.title}</p>
            <p className="text-sm text-gray-500 leading-tight text-ellipsis">
              Created{" "}
              {formatDistanceToNow(fromUnixTime(props.question.createdAt), {
                addSuffix: true,
              })}
            </p>
          </div>
          <div className="flex gap-x-2">
            <ChatBubbleLeftIcon className="mt-0.5 w-5 h-5 text-gray-500" />
            <p className="text-gray-600">
              {
                props.question.activity.filter(
                  (a) => a.type === DealActivityType.Comment
                ).length
              }
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
import {
  Notification,
  NotificationType,
  useMarkNotificationAsReadMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { classNames } from "@/src/utils/cn";
import { renderMentions } from "@/src/utils/renderMentions";
import { Popover } from "@headlessui/react";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { useHistory } from "react-router-dom";
import { NotificationIcon } from "./NotificationIcon";

function getNavLink(notification: Notification) {
  switch (notification.type) {
    case NotificationType.TaskAssigned:
    case NotificationType.TaskUrgent:
      if (!notification.task) {
        return "/";
      }
      return `/tasks/${notification.task.id}`;
    case NotificationType.Comment:
      if (notification.task) {
        return `/tasks/${notification.task.id}`;
      }
      return "/";
    default:
      return "/";
  }
}

export function NotificationItem(props: {
  notification: Notification;
  isLast: boolean;
  isLastUnread: boolean;
}) {
  const notification = props.notification;
  const history = useHistory();
  const client = useGqlClient();
  const markNotificationAsRead = useMarkNotificationAsReadMutation(client);

  function onClick(notification: Notification) {
    switch (notification.type) {
      case NotificationType.TaskAssigned:
        console.log("task assigned");
    }

    markNotificationAsRead.mutate({ id: notification.id });
  }

  return (
    <Popover.Button
      onClick={() => {
        onClick(notification);
        history.push(getNavLink(notification));
      }}
      className={classNames(
        "w-full px-3 py-3 hover:bg-gray-100 cursor-pointer border-b border-gray-100",
        props.isLast ? "rounded-b-md" : "",
        notification.readAt ? "opacity-70 bg-gray-50" : "",
        props.isLastUnread ? "" : ""
      )}
    >
      <div className="flex items-start justify-between relative">
        <div className="flex items-center gap-x-3">
          <NotificationIcon notification={notification} />
          <div className="max-w-96 text-left ml-1">
            <NotificationTitle notification={notification} />
            <NotificationMessage notification={notification} />
            <NotificationDots notification={notification} />
          </div>
        </div>
        <div className="h-full flex flex-col items-end">
          {!notification.readAt && (
            <div className="w-1.5 h-1.5 bg-blue-500 rounded-full absolute top-1/2 -translate-y-1/2"></div>
          )}
        </div>
      </div>
    </Popover.Button>
  );
}

function NotificationMessage(props: { notification: Notification }) {
  let defaultReturn = (
    <p className="text-sm text-gray-500">{props.notification.message}</p>
  );
  if (props.notification.type === NotificationType.TaskAssigned) {
    if (!props.notification.task) {
      return defaultReturn;
    }

    return (
      <p className="text-sm text-gray-500">{props.notification.task.title}</p>
    );
  }

  if (props.notification.type === NotificationType.Comment) {
    return (
      <div className="ring-1 ring-inset ring-gray-200 bg-white/50 rounded-md p-2 mt-2">
        <p className="text-sm text-gray-600 text-left ">
          {renderMentions(props.notification.message)}
        </p>
      </div>
    );
  }
  return defaultReturn;
}

function NotificationTitle(props: { notification: Notification }) {
  if (props.notification.type === NotificationType.TaskAssigned) {
    if (!props.notification.initiator) {
      return (
        <p className="text-sm font-semibold text-gray-700">
          {props.notification.title}
        </p>
      );
    }

    return (
      <p className="text-sm font-semibold text-gray-700">
        <span className="font-semibold">
          {props.notification.initiator.firstName}
        </span>{" "}
        assigned a task to you
      </p>
    );
  }

  if (props.notification.type === NotificationType.Comment) {
    if (props.notification.task && props.notification.initiator) {
      return (
        <p className="text-sm font-semibold text-gray-700">
          <span className="font-semibold">
            {props.notification.initiator.firstName}
          </span>{" "}
          mentioned you in a task
        </p>
      );
    }
  }

  return (
    <p className="text-sm font-semibold text-gray-700">
      {props.notification.title}
    </p>
  );
}

function NotificationDots(props: { notification: Notification }) {
  let dots = [
    formatDistanceToNowStrict(fromUnixTime(props.notification.createdAt), {
      addSuffix: true,
    }),
  ];

  if (
    props.notification.task &&
    props.notification.type === NotificationType.Comment
  ) {
    dots.push(props.notification.task.title);
  }

  if (props.notification.task?.deal) {
    dots.push(props.notification.task.deal.company.name);
  }

  return (
    <p className="mt-1.5 text-xs text-gray-400">{dots.join(" \u2022 ")}</p>
  );
}

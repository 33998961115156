import { TextDiff, TextDiffType } from "../../graphql/generated";
import React from "react";

export function TextDiffs(props: { diffs: TextDiff[] }) {
  if (!props.diffs) {
    return null;
  }

  return (
    <p className="text-xs">
      {props.diffs.map((diff, index) => {
        let className = "";
        switch (diff.type) {
          case TextDiffType.Delete:
            className = "text-red-700 bg-red-100 line-through";
            break;
          case TextDiffType.Insert:
            className = "bg-green-100 text-green-700";
            break;
          case TextDiffType.Equal:
            className = "text-gray-500";
            break;
        }

        // Split on <p> or </p> and filter out empty strings
        const lines = diff.text
          .split(/<\/?p[^>]*>/gi)
          .map((str) => str.trim())
          .filter(Boolean);

        return (
          <span key={index} className={className}>
            {lines.map((line, i) => (
              <React.Fragment key={i}>
                {/* Insert a <br/> before all but the first line. */}
                {i > 0 && <br />}
                {line}
              </React.Fragment>
            ))}
          </span>
        );
      })}
    </p>
  );
}

import { NavLink, useParams } from "react-router-dom";
import {
  AnswerReference,
  DataRoomFile,
  FileType,
  QuestionQuery,
  SuggestedAnswerStatus,
  useCreateCommentMutation,
  useCreateQuestionAnswerMutation,
  useDataRoomFileQuery,
  useQuestionQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { H3, H4 } from "../../components/Heading";
import { Card } from "../../components/Card";
import { useEffect, useRef, useState } from "react";
import { AddFileModal } from "../../components/AddFileModal";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { FilePill } from "../../components/FilePill";
import { Avatar } from "../../components/account/Avatar";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../components/tailwind/Button";
import { Pills } from "../../components/Pills";
import { ActivityItem } from "../../components/activity/ActivityItem";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Markdown from "react-markdown";
import {
  adminRoles,
  DealRoleRestricted,
} from "../../components/DealRoleRestricted";
import { classNames } from "@/src/utils/cn";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Loading from "../../components/Loading";
import {
  BotIcon,
  InfoIcon,
  ChevronUpIcon,
  ExternalLinkIcon,
  PanelRightCloseIcon,
  PlusIcon,
  SearchCheckIcon,
} from "lucide-react";
import { FileIcon } from "@/src/components/FileIcon";
import { SlideOver } from "@/src/components/SlideOver";
import { FileViewer } from "@/src/components/FileViewer";
import { Spinner } from "@/src/components/icons/Spinner";
import TextareaAutosize from "react-textarea-autosize";

export function Question() {
  const { id } = useParams<{ id: string }>();

  const client = useGqlClient();
  const { data, isLoading, error } = useQuestionQuery(
    client,
    { id },
    {
      refetchInterval(query) {
        if (
          query.state.data &&
          query.state.data.question.suggestedAnswer &&
          query.state.data.question.suggestedAnswer.status ===
            SuggestedAnswerStatus.Complete
        ) {
          return false;
        }

        return 1000;
      },
    }
  );

  return (
    <div className="flex-1 mb-8 flex flex-col">
      <div className="bg-white fixed z-20 px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/deal/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            {data && data.question.ddql ? (
              <>
                <p className="font-light text-gray-400">/</p>
                <NavLink to={`/deal/questions/lists/${data.question.ddql.id}`}>
                  <h4 className="font-bold text-gray-400 hover:text-gray-700">
                    {!data || isLoading
                      ? "Loading..."
                      : data.question.ddql.name}
                  </h4>
                </NavLink>
              </>
            ) : null}
            <p className="font-light text-gray-400">/</p>
            <H4>{data ? data.question.title : "..."}</H4>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 flex-1">
        <QuestionInner isLoading={isLoading} error={error} data={data} />
      </div>
    </div>
  );
}

function QuestionInner(props: {
  isLoading: boolean;
  data?: QuestionQuery;
  error: any;
}) {
  if (props.error) {
    return (
      <div className="flex-1 justify-center items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.data) {
    return (
      <div className="flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return <QuestionContent question={props.data.question} />;
}

function QuestionContent(props: { question: QuestionQuery["question"] }) {
  const [answer, setAnswer] = useState("");
  const [answerFiles, setAnswerFiles] = useState<AnswerReference[]>([]);

  const account = useSelector(authSelectors.account);

  const [openModal, setOpenModal] = useState<"add-document" | "">("");

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const createQuestionAnswer = useCreateQuestionAnswerMutation(client);

  const [selectedReference, setSelectedReference] = useState<
    AnswerReference | undefined
  >(undefined);

  if (!account) {
    return null;
  }

  return (
    <div className="p-8 flex-1">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div className="flex space-x-2 mt-2">
            {props.question.createdBy ? (
              <div className="mt-2">
                <Avatar account={props.question.createdBy} />
              </div>
            ) : null}
            <div className="flex-1">
              <Card padding="m">
                <p className=" text-gray-600 font-semibold">
                  <Markdown>{props.question.title}</Markdown>
                </p>
                <p className="whitespace-pre-wrap text-sm text-gray-600 mt-2">
                  <Markdown>{props.question.body}</Markdown>
                </p>
                {props.question.dataRoomFile ? (
                  <Pills>
                    <FilePill
                      id={props.question.dataRoomFile.id}
                      name={props.question.dataRoomFile.name}
                      type={props.question.dataRoomFile.fileType}
                    />
                  </Pills>
                ) : null}
              </Card>
            </div>
          </div>
          <div className="mt-6">
            <H3>Answer</H3>
            <div className="mt-6 flex gap-x-3 ">
              {props.question.answer ? (
                <div className="flex space-x-2 w-full">
                  {props.question.answer.answeredBy ? (
                    <div className="mt-2">
                      <Avatar account={props.question.answer.answeredBy} />
                    </div>
                  ) : null}
                  <div className="flex-1 w-full">
                    <Card padding="s m">
                      <div className="flex items-center justify-between">
                        <p className="font-semibold text-gray-700 text-sm">
                          Answered by {props.question.answer.answeredBy.name}
                        </p>
                        <p className="text-xs text-gray-500/80">
                          {formatDistanceToNow(
                            fromUnixTime(props.question.answer.createdAt),
                            {
                              addSuffix: true,
                            }
                          )}
                        </p>
                      </div>
                      <p className="whitespace-pre-wrap text-sm text-gray-700 mt-2">
                        <Markdown>{props.question.answer.answer}</Markdown>
                      </p>
                      <Pills margin="l 0 0 0">
                        {props.question.answer.answerFiles.map((file) => {
                          return (
                            <div
                              key={file.file.id}
                              className="flex items-center"
                            >
                              <FilePill
                                id={file.file.id}
                                name={file.file.name}
                                type={file.file.fileType}
                                pageIndex={file.pageIndex ?? undefined}
                                rectsOnPage={file.rectsOnPage}
                                checkFileAccessAccountId={
                                  props.question.createdBy.id
                                }
                                noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                                onRemove={() => {
                                  setAnswerFiles(
                                    answerFiles.filter(
                                      (f) => f.file.id !== file.file.id
                                    )
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </Pills>
                    </Card>
                  </div>
                </div>
              ) : (
                <div className="flex space-x-2 w-full">
                  <Avatar account={account} />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (!answer) {
                        return;
                      }

                      createQuestionAnswer.mutate(
                        {
                          questionId: props.question.id,
                          answer,
                          dataRoomFileIds: answerFiles.map((f) => f.file.id),
                          answerFiles: answerFiles.map((f) => {
                            return {
                              dataRoomFileID: f.file.id,
                              pageIndex: f.pageIndex,
                              rectsOnPage: f.rectsOnPage,
                            };
                          }),
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries({
                              queryKey: ["Question", { id: props.question.id }],
                            });
                          },
                        }
                      );
                    }}
                    className="relative flex-auto"
                  >
                    <div className="rounded-lg pb-12 focus-within:bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
                      <label htmlFor="comment" className="sr-only">
                        Write your answer
                      </label>
                      <TextareaAutosize
                        rows={6}
                        name="comment"
                        id="comment"
                        className="p-4 focus:outline-none block w-full no-scrollbar resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Write your answer..."
                        defaultValue={""}
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.currentTarget.value);
                        }}
                      />
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                      <div className="no-scrollbar  flex items-center py-1 space-x-2  mr-2">
                        <div className="flex items-center gap-2  mr-2">
                          {answerFiles.map((file) => {
                            return (
                              <div
                                key={file.file.id}
                                className="flex items-center"
                              >
                                <FilePill
                                  id={file.file.id}
                                  name={file.file.name}
                                  type={file.file.fileType}
                                  pageIndex={file.pageIndex ?? undefined}
                                  rectsOnPage={file.rectsOnPage}
                                  checkFileAccessAccountId={
                                    props.question.createdBy.id
                                  }
                                  showDetailsCard={false}
                                  noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                                  onRemove={() => {
                                    setAnswerFiles(
                                      answerFiles.filter(
                                        (f) =>
                                          referenceHash(f) !==
                                          referenceHash(file)
                                      )
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                          <button
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenModal("add-document");
                            }}
                            className="bg-gray-100 border hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl px-2 py-1"
                          >
                            <PlusIcon className="h-5 w-5 text-gray-600/80" />
                            <p className="ml-1 text-xs text-gray-600 truncate">
                              Add document
                            </p>
                          </button>
                        </div>
                      </div>
                      <div>
                        <Button
                          isLoading={createQuestionAnswer.isPending}
                          loadingText="Answering..."
                          size="s"
                          variant="neutral"
                          type="submit"
                          text="Answer"
                        />
                      </div>
                    </div>
                  </form>
                  <AddFileModal
                    open={openModal === "add-document"}
                    onClose={() => {
                      setOpenModal("");
                    }}
                    onFileSelected={(file) => {
                      if (answerFiles.find((f) => f.file.id === file.id)) {
                        return;
                      }

                      setAnswerFiles([
                        ...answerFiles,
                        {
                          file: file as DataRoomFile,
                          pageIndex: 0,
                          rectsOnPage: [],
                          quote: "",
                        },
                      ]);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <DealRoleRestricted roles={adminRoles}>
            <SimilarAnswers
              question={props.question}
              setAnswer={setAnswer}
              setAnswerFiles={setAnswerFiles}
              setSelectedReference={setSelectedReference}
            />
            <SuggestedAnswerWrapper
              question={props.question}
              setAnswer={setAnswer}
              setAnswerFiles={setAnswerFiles}
              setSelectedReference={setSelectedReference}
              selectedReference={selectedReference}
              answerFiles={answerFiles}
            />
          </DealRoleRestricted>
        </div>

        <Reference
          reference={selectedReference}
          open={!!selectedReference}
          onClose={() => setSelectedReference(undefined)}
        />

        <div>
          <H3>Activity</H3>
          <Activity question={props.question} />
        </div>
      </div>
    </div>
  );
}

function SimilarAnswers(props: {
  question: QuestionQuery["question"];
  setAnswer: (answer: string) => void;
  setAnswerFiles: (files: AnswerReference[]) => void;
  setSelectedReference: (reference: AnswerReference | undefined) => void;
}) {
  if (props.question.similarQuestions.length === 0) {
    return null;
  }

  return (
    <Card padding="m" margin="l 0 0 xxl">
      <div className="flex items-center space-x-2">
        <SearchCheckIcon className="w-4 h-4 text-persian-500" />
        <p className="text-sm text-persian-500 font-semibold">
          Similar questions to this have been answered before
        </p>
      </div>
      <div className="mt-2 space-y-2">
        {props.question.similarQuestions.map((question) => {
          return (
            <SimilarAnswer
              question={question}
              setAnswer={props.setAnswer}
              setAnswerFiles={props.setAnswerFiles}
              setSelectedReference={props.setSelectedReference}
            />
          );
        })}
      </div>
    </Card>
  );
}

function SimilarAnswer(props: {
  question: QuestionQuery["question"]["similarQuestions"][0];
  setAnswer: (answer: string) => void;
  setAnswerFiles: (files: AnswerReference[]) => void;
  setSelectedReference: (reference: AnswerReference | undefined) => void;
}) {
  const question = props.question;
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div key={question.id} className="p-3 bg-gray-50 rounded-md">
      {!showDetails ? (
        <button
          onClick={() => setShowDetails(true)}
          className="w-full flex items-center justify-between"
        >
          <p className="text-md text-gray-700 font-semibold">
            {question.title}
          </p>
          <div className="flex items-center gap-x-2">
            <p className="text-xs text-gray-500">
              asked{" "}
              {formatDistanceToNow(fromUnixTime(question.createdAt ?? 0), {
                addSuffix: true,
              })}{" "}
              by {question.createdBy.name}
            </p>
            <ChevronDownIcon className="w-5 h-5 text-gray-500 transition-transform" />
          </div>
        </button>
      ) : (
        <>
          <button
            onClick={() => setShowDetails(false)}
            className="w-full flex items-center justify-between"
          >
            <p className="text-md text-gray-700 font-semibold">
              {question.title}
            </p>
            <ChevronDownIcon className="w-5 h-5 text-gray-500 transition-transform rotate-180" />
          </button>

          <div className="mt-2 flex items-center space-x-2">
            <Avatar account={question.createdBy} />
            <div className="bg-white border border-gray-200 rounded-md p-2 flex-1">
              <div className="flex items-center justify-between">
                <p className="text-md text-gray-700 font-semibold">
                  {question.title}
                </p>
                <p className="text-xs text-gray-500/80">
                  asked{" "}
                  {formatDistanceToNow(fromUnixTime(question.createdAt ?? 0), {
                    addSuffix: true,
                  })}
                </p>
              </div>
              {question.body ? (
                <p className="text-sm text-gray-500">
                  {question.body.slice(0, 100)}...
                </p>
              ) : null}
            </div>
          </div>
          {question.answer ? (
            <div className="mt-2 flex items-start gap-x-2">
              <Avatar account={question.answer.answeredBy} />
              <div className="bg-white border border-gray-200 rounded-md p-2 flex-1">
                <div className="flex items-center justify-between">
                  <p className="text-sm text-gray-700">
                    Answered by{" "}
                    <span className="font-semibold">
                      {question.answer.answeredBy.name}
                    </span>
                  </p>
                  <p className="text-xs text-gray-500/80">
                    answered{" "}
                    {formatDistanceToNow(
                      fromUnixTime(question.answeredAt ?? 0),
                      {
                        addSuffix: true,
                      }
                    )}
                  </p>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  {question.answer.answer}
                </p>
                <Pills>
                  {question.answer.answerFiles
                    .map((file) => {
                      return {
                        file: file.file,
                        pageIndex: file.pageIndex,
                        rectsOnPage: file.rectsOnPage,
                        quote: "",
                      } as AnswerReference;
                    })
                    .map((file) => {
                      return (
                        <ReferencePill
                          reference={file}
                          onReferenceSelected={() => {
                            props.setSelectedReference(file);
                          }}
                        />
                      );
                    })}
                </Pills>

                <Button
                  margin="m 0 0 0"
                  variant="neutral"
                  size="s"
                  text="Use answer"
                  onClick={() => {
                    if (!question.answer) {
                      return;
                    }
                    props.setAnswer(question.answer.answer);
                    props.setAnswerFiles(
                      question.answer.answerFiles as AnswerReference[]
                    );
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

function Activity(props: { question: QuestionQuery["question"] }) {
  const account = useSelector(authSelectors.account);
  const [isFocused, setIsFocused] = useState(false);

  const queryClient = useQueryClient();
  const client = useGqlClient();

  const createComment = useCreateCommentMutation(client);

  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const [comment, setComment] = useState("");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [openModal, setOpenModal] = useState<"add-document" | "">("");

  const createCommentRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!openModal && textAreaRef.current) {
      const timer = setTimeout(() => {
        textAreaRef.current?.focus();
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [openModal]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setComment("");
    setFiles([]);
  }

  function onSubmit() {
    if (!comment) {
      return;
    }

    createComment.mutate(
      {
        input: {
          questionId: props.question.id,
          comment,
          files: files.map((f) => f.id),
          dataRoomFileId: "",
          dataRoomFolderId: "",
          dataRoomId: "",
          dataRoomFileErrorId: "",
        },
      },
      {
        onSuccess: () => {
          clearForm();
          queryClient.invalidateQueries({
            queryKey: ["Question", { id: props.question.id }],
          });
        },
      }
    );
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (
        !document.activeElement ||
        !document.activeElement.closest("#comment_form")
      ) {
        setIsFocused(false);
      }
    }, 0);
  };

  if (!account) {
    return null;
  }

  return (
    <>
      <ul role="list">
        <TransitionGroup className="space-y-6 mt-2">
          {props.question.activity
            .map((activity, i) => {
              return (
                <CSSTransition
                  key={activity.id}
                  timeout={300} // This controls the duration of the animation
                  classNames="fade-slide-down"
                  onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
                >
                  <ActivityItem
                    key={activity.id}
                    activity={activity}
                    isFirst={i === props.question.activity.length - 1}
                    isLast={i === 0}
                  />
                </CSSTransition>
              );
            })
            .reverse()}
        </TransitionGroup>
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 " onBlur={handleBlur}>
        <Avatar account={account} />
        <form
          id="comment_form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="relative flex-auto"
        >
          <div
            className={`overflow-hidden rounded-lg ${
              isFocused ? "pb-12" : "pb-1"
            } shadow-sm ring-1 ring-inset transition-all ease-in-out duration-300 ring-gray-300 focus-within:bg-white focus-within:ring-indigo-500 focus:ring-2`}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              ref={textAreaRef}
              rows={isFocused ? 2 : 1}
              name="comment"
              id="comment"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              value={comment}
              onChange={(e) => {
                setComment(e.currentTarget.value);
              }}
              defaultValue={""}
            />
          </div>

          {/* <div
            className={`ring-0  absolute top-0 right-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Dropdown
              options={[{ value: 1, label: "Visible to sell side" }]}
              defaultValue="Visible to sell side"
              dropdownType="text"
              onSelect={() => {}}
            />
          </div> */}

          <div
            className={`ring-0  absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Pills>
              {files.map((file) => {
                return (
                  <FilePill
                    id={file.id}
                    name={file.name}
                    type={file.fileType}
                    checkFileAccessAccountId={props.question.createdBy.id}
                    noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                    onRemove={() => {
                      setFiles(files.filter((f) => f.id !== file.id));
                    }}
                  />
                );
              })}
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal("add-document");
                }}
                className="bg-gray-100 border hover:border-gray-400 hover:shadow-sm
                   flex items-center rounded-2xl px-2 py-1"
              >
                <PlusIcon className="h-5 w-5 text-gray-600/80" />
                <p className="ml-1 text-xs text-gray-600 truncate">
                  Add document
                </p>
              </button>
            </Pills>
            <div>
              <Button
                ref={createCommentRef}
                variant="neutral"
                size="s"
                text="Comment"
                isLoading={createComment.isPending}
                loadingText="Commenting..."
                type="submit"
              />
            </div>
          </div>
        </form>
        <AddFileModal
          open={openModal === "add-document"}
          onClose={() => {
            setOpenModal("");
          }}
          onFileSelected={(file) => {
            if (files.find((f) => f.id === file.id)) {
              return;
            }

            setFiles([...files, file as DataRoomFile]);
          }}
        />
      </div>
    </>
  );
}

function SuggestedAnswerNoResultsText() {
  return (
    <Card padding="m" margin="l 0 0 xxl">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <BotIcon className="w-4 h-4 text-persian-500" />
          <p className="text-sm text-persian-500 font-semibold">
            No relevant references found
          </p>
        </div>
      </div>
    </Card>
  );
}

function SuggestedAnswerStatusText(props: { status: SuggestedAnswerStatus }) {
  let statusText = "";
  if (
    props.status === SuggestedAnswerStatus.Initial ||
    props.status === SuggestedAnswerStatus.FindingRelevantFiles
  ) {
    statusText = "Finding relevant documents...";
  } else if (props.status === SuggestedAnswerStatus.CheckingAnswer) {
    statusText = "Generating references...";
  }

  return (
    <Card padding="m" margin="l 0 0 xxl">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <BotIcon className="w-4 h-4 text-persian-500" />
          <p className="text-sm text-persian-500 font-semibold">{statusText}</p>
        </div>
        <Spinner color="gray" size="s" />
      </div>
    </Card>
  );
}

function SuggestedAnswerWrapper(props: {
  question: QuestionQuery["question"];
  setAnswer: (answer: string) => void;
  setAnswerFiles: (files: AnswerReference[]) => void;
  answerFiles: AnswerReference[];
  setSelectedReference: (ref: AnswerReference | undefined) => void;
  selectedReference: AnswerReference | undefined;
}) {
  if (
    props.question.suggestedAnswer?.status === SuggestedAnswerStatus.Complete &&
    props.question.suggestedAnswer?.references.length === 0
  ) {
    return <SuggestedAnswerNoResultsText />;
  } else if (
    props.question.suggestedAnswer &&
    props.question.suggestedAnswer.status !== SuggestedAnswerStatus.Complete &&
    props.question.suggestedAnswer.status !==
      SuggestedAnswerStatus.CheckingAnswer
  ) {
    return (
      <SuggestedAnswerStatusText
        status={props.question.suggestedAnswer.status}
      />
    );
  }

  return (
    <div>
      <SuggestedAnswer
        question={props.question}
        summary={props.question.suggestedAnswer?.summary}
        onSelected={(answer, files) => {
          props.setAnswer(answer);
          props.setAnswerFiles(files);
        }}
        onReferenceSelected={(ref) => {
          props.setSelectedReference(ref);
        }}
        setAnswerFiles={props.setAnswerFiles}
        answerFiles={props.answerFiles}
      />
    </div>
  );
}

function NoSummary(props: { status: SuggestedAnswerStatus | undefined }) {
  return (
    <>
      <div className="mt-2 bg-persian-500 space-x-2 text-sm font-semibold text-persian-600 bg-opacity-10 p-3 rounded-md flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <InfoIcon className="w-4 h-4" />
          {props.status === SuggestedAnswerStatus.Complete ? (
            <span>Summary Not Available</span>
          ) : (
            <span>Summary Generating</span>
          )}
        </div>
        {props.status !== SuggestedAnswerStatus.Complete && (
          <Spinner color="gray" size="s" />
        )}
      </div>
    </>
  );
}

function SuggestedAnswer(props: {
  question: QuestionQuery["question"];
  summary?: string;
  onSelected: (answer: string, files: AnswerReference[]) => void;
  onReferenceSelected: (reference: AnswerReference) => void;
  setAnswerFiles: (files: AnswerReference[]) => void;
  answerFiles: AnswerReference[];
}) {
  const question = props.question;
  const status = question.suggestedAnswer?.status;
  const summary = props.summary;
  const account = useSelector(authSelectors.account);

  const [expanded, setExpanded] = useState(true);

  if (question.answer) {
    return null;
  }

  if (!question.suggestedAnswer) {
    return null;
  }

  return (
    <>
      <div className="ml-8 mt-3 transition-all duration-300 ease-in-out p-3 rounded-md shadow bg-white">
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {expanded ? (
                <ChevronDownIcon
                  onClick={() => setExpanded(false)}
                  className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              ) : (
                <ChevronRightIcon
                  onClick={() => setExpanded(true)}
                  className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              )}
              <BotIcon className="w-4 h-4 text-persian-500" />
              <p className="text-persian-500 font-semibold text-sm">
                Relevant documents
              </p>
            </div>
          </div>
          {summary ? (
            <div className="mt-2 bg-persian-500 bg-opacity-10 p-5 rounded-md">
              <div className="flex items-center mb-3">
                <InfoIcon className="w-4 h-4 inline mr-2 text-persian-600" />
                <p className="text-sm font-semibold text-persian-600 inline">
                  Summary
                </p>
                <p className="text-xs text-gray-400 inline ml-auto">
                  AI Generated
                </p>
              </div>
              <p className="text-sm text-gray-500">{summary}</p>
            </div>
          ) : (
            <NoSummary status={status} />
          )}
        </div>

        {expanded ? (
          <div>
            <div
              className={classNames(
                "w-full bg-gray-200 h-px mt-3",
                expanded ? "mb-3" : ""
              )}
            ></div>
            <References
              question={question}
              onReferenceSelected={props.onReferenceSelected}
              setAnswerFiles={props.setAnswerFiles}
              answerFiles={props.answerFiles}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

function ReferencePill(props: {
  reference: AnswerReference;
  onReferenceSelected: (reference: AnswerReference) => void;
}) {
  return (
    <button
      onClick={() => {
        props.onReferenceSelected(props.reference);
      }}
      className="flex flex-wrap group justify-center text-left relative"
    >
      <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm rounded-2xl px-2 py-1 bg-white border border-gray-200 flex items-center space-x-2">
        <FileIcon size="s" fileType={props.reference.file.fileType} />
        <p className="text-xs ml-1 text-gray-800 truncate">
          {props.reference.file.name}
        </p>
        {props.reference.pageIndex !== undefined ? (
          <p className="text-xs truncate text-gray-500/80 ml-1 inline">
            Page {props.reference.pageIndex + 1}
          </p>
        ) : null}
      </div>
      {props.reference.quote ? (
        <span
          className={`transition-all p-3 z-20 delay-300  absolute ${"bottom-8"} rounded-md shadow scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 border border-gray-200 bg-white w-64`}
        >
          <div className="p-3 rounded-md border border-gray-200">
            <p className="text-xs font-semibold text-gray-700">
              {props.reference.quote}
            </p>
          </div>
        </span>
      ) : null}
    </button>
  );
}

function Reference(props: {
  reference?: AnswerReference;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <SlideOver
      open={props.open}
      onClose={props.onClose}
      title="Reference"
      maxWidth="max-w-3xl"
    >
      <div>
        <div className="border-b border-gray-200 shadow-sm p-4 flex justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <PanelRightCloseIcon
              onClick={() => props.onClose()}
              className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
            />
            <FileIcon
              fileType={props.reference?.file.fileType ?? FileType.Other}
            />
            <p className="text-sm text-gray-800 font-semibold">
              {props.reference?.file.name}
            </p>
          </div>
          <div>
            <Button
              variant="neutral"
              size="s"
              text="Go to document"
              icon={ExternalLinkIcon}
            />
          </div>
        </div>
        <div className="h-full flex flex-col">
          {props.reference ? (
            <ReferenceFileView reference={props.reference} />
          ) : null}
        </div>
      </div>
    </SlideOver>
  );
}

function ReferenceFileView(props: { reference: AnswerReference }) {
  const client = useGqlClient();
  const dataRoomFile = useDataRoomFileQuery(client, {
    id: props.reference.file.id,
  });

  if (dataRoomFile.isLoading || !dataRoomFile.data) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (dataRoomFile.error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <p className="text-sm text-gray-500/80">
          Error loading file. Please try again.
        </p>
      </div>
    );
  }

  const file = dataRoomFile.data.dataRoomFile;
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <FileViewer
        dataRoomFileId={file.id}
        dataRoomFileVersionId={file.currentLiveVersion.id}
        pageIndex={props.reference.pageIndex}
        rectsOnPage={
          props.reference.rectsOnPage.length > 0
            ? props.reference.rectsOnPage
            : undefined
        }
      />
    </div>
  );
}

type GroupedReference = {
  file: Pick<DataRoomFile, "id" | "name" | "fileType">;
  quotes: AnswerReference[];
};

function References(props: {
  question: QuestionQuery["question"];
  onReferenceSelected: (ref: AnswerReference) => void;
  setAnswerFiles: (files: AnswerReference[]) => void;
  answerFiles: AnswerReference[];
}) {
  const question = props.question;
  const account = useSelector(authSelectors.account);

  const [groupedReferences, setGroupedReferences] = useState<
    GroupedReference[]
  >([]);

  useEffect(() => {
    if (!question.suggestedAnswer) {
      return;
    }

    const groupedReferences: GroupedReference[] = [];

    const references = [...(question.suggestedAnswer.references ?? [])];
    references.forEach((ref) => {
      const existingGroup = groupedReferences.find(
        (g) => g.file.id === ref.file.id
      );
      if (existingGroup) {
        existingGroup.quotes.push({
          pageIndex: ref.pageIndex,
          rectsOnPage: ref.rectsOnPage,
          quote: ref.quote,
          file: ref.file as DataRoomFile,
        });
      } else {
        groupedReferences.push({
          file: ref.file,
          quotes: [
            {
              pageIndex: ref.pageIndex,
              rectsOnPage: ref.rectsOnPage,
              quote: ref.quote,
              file: ref.file as DataRoomFile,
            },
          ],
        });
      }
    });

    setGroupedReferences(groupedReferences);
  }, [question.suggestedAnswer]);

  if (question.answer) {
    return null;
  }

  if (!question.suggestedAnswer) {
    return null;
  }

  return (
    <div>
      {groupedReferences.map((group) => (
        <GroupedReferenceItem
          key={group.file.id}
          group={group}
          onReferenceSelected={props.onReferenceSelected}
          onReferenceAdded={(reference: AnswerReference) => {
            props.setAnswerFiles([...props.answerFiles, reference]);
          }}
        />
      ))}
    </div>
  );
}

function GroupedReferenceItem(props: {
  group: {
    file: {
      id: string;
      name: string;
      fileType: FileType;
    };
    quotes: AnswerReference[];
  };
  onReferenceSelected: (reference: AnswerReference) => void;
  onReferenceAdded: (reference: AnswerReference) => void;
}) {
  const [showReferences, setShowReferences] = useState(false);

  // If no references generated yet, render just the file details
  if (props.group.quotes.filter((quote) => quote.quote !== "").length === 0) {
    return (
      <div
        key={props.group.file.id}
        className="bg-gray-50 p-3 rounded-md w-full flex-1"
      >
        <div className="flex items-center justify-between space-x-2">
          <FilePill
            id={props.group.file.id}
            name={props.group.file.name}
            type={props.group.file.fileType}
          />
          <Spinner color="gray" size="s" />
        </div>
      </div>
    );
  }

  return (
    <div
      key={props.group.file.id}
      className="bg-gray-50 p-3 rounded-md w-full flex-1 mb-3"
    >
      <div
        className={classNames(
          "flex items-center justify-between space-x-2",
          showReferences ? "mb-2" : ""
        )}
      >
        <FilePill
          id={props.group.file.id}
          name={props.group.file.name}
          type={props.group.file.fileType}
        />
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setShowReferences(!showReferences)}
            className="flex items-center space-x-2 text-gray-500 hover:text-gray-700"
          >
            <p className="text-xs">
              {showReferences ? "Hide" : "Show"} {props.group.quotes.length}{" "}
              references
            </p>
            {showReferences ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </button>
        </div>
      </div>
      {showReferences ? (
        <div className="ml-3">
          {props.group.quotes.map((quote) => {
            return (
              <div
                key={quote.quote}
                className="p-3 hover:border-gray-300 cursor-pointer bg-white border my-1 border-gray-200 rounded-md"
                onClick={() => {
                  props.onReferenceSelected(quote);
                }}
              >
                <div className="flex items-center justify-between mb-1">
                  <p className="text-xs text-gray-500/80">
                    Page {quote.pageIndex + 1}
                  </p>
                </div>
                <p className="text-sm text-gray-600">{quote.quote}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onReferenceAdded(quote);
                  }}
                  className="mt-2 flex items-center space-x-2 text-xs font-semibold text-persian-500/80 hover:text-persian-500"
                >
                  <PlusIcon className="w-4 h-4" />
                  Add to answer
                </button>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

function referenceHash(reference: AnswerReference) {
  let hash = reference.file.id;
  if (reference.pageIndex !== undefined) {
    hash += `-${reference.pageIndex}`;
  }
  if (reference.quote) {
    hash += `-${reference.quote}`;
  }
  if (reference.rectsOnPage?.length) {
    hash += `-${reference.rectsOnPage}`;
  }
  return hash;
}

import {
  NotificationsQuery,
  useNotificationsQuery,
  Notification,
  useMarkNotificationAsReadMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { Popover } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import { NotificationItem } from "./NotificationItem";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import Loading from "../Loading";

export function NotificationPanel() {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const notifications = useNotificationsQuery(client);
  const markNotificationAsRead = useMarkNotificationAsReadMutation(client);

  return (
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel className="absolute mt-1 z-10 bg-white rounded-md shadow-md ">
        <div className="w-[600px] max-w-700px max-h-[600px] overflow-y-auto rounded-md">
          {notifications.data &&
          notifications.data.notifications.filter((n) => !n.readAt).length >
            0 ? (
            <>
              <div className="z-20 sticky top-0 px-3 py-2 flex items-center justify-between bg-white">
                <p className="font-semibold text-blue-600 text-sm">
                  New for you
                </p>
                <button
                  className="text-sm  text-gray-400 hover:text-gray-500"
                  onClick={() => {
                    if (!notifications.data) {
                      return;
                    }

                    const unread = notifications.data.notifications.filter(
                      (n) => !n.readAt
                    );
                    for (const u of unread) {
                      markNotificationAsRead.mutate({
                        id: u.id,
                      });
                    }

                    queryClient.invalidateQueries({
                      queryKey: ["Notifications"],
                    });
                  }}
                >
                  Mark all read
                </button>
              </div>
            </>
          ) : null}
          <NotificationsInner query={notifications} />
        </div>
      </Popover.Panel>
    </Transition>
  );
}

function NotificationsInner(props: {
  query: UseQueryResult<NotificationsQuery, unknown>;
}) {
  if (props.query.error) {
    return (
      <div className="p-3">
        <p className="text-sm text-gray-400">Error loading notifications</p>
      </div>
    );
  }
  if (props.query.isLoading || !props.query.data) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    );
  }

  return (
    <div className="">
      <div>
        {props.query.data.notifications
          .filter((n) => !n.readAt)
          .map((notification, i) => {
            return (
              <NotificationItem
                key={notification.id}
                notification={notification as Notification}
                isLastUnread={
                  props.query.data &&
                  i ===
                    props.query.data.notifications.filter((n) => !n.readAt)
                      .length -
                      1
                    ? true
                    : false
                }
                isLast={
                  props.query.data &&
                  i === props.query.data.notifications.length - 1
                    ? true
                    : false
                }
              />
            );
          })}
      </div>
      <p className="text-sm font-semibold text-gray-700 p-3 bg-gray-50">
        Previous notifications
      </p>
      <div>
        {props.query.data.notifications
          .filter((n) => n.readAt)
          .map((notification, i) => {
            return (
              <NotificationItem
                key={notification.id}
                notification={notification as Notification}
                isLastUnread={false}
                isLast={
                  props.query.data &&
                  i === props.query.data.notifications.length - 1
                    ? true
                    : false
                }
              />
            );
          })}
      </div>
    </div>
  );
}

import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Login } from "./Login";
import { Signup } from "./Signup";
import ProtectedRoute from "../components/ProtectedRoute";
import { Navigation } from "../components/navigation";
import { DataRoom } from "./DataRoom/DataRoom";
import { DataRoomFile } from "./DataRoom/data_room_file";
import { Access } from "./access";
import { Invite } from "./Invite";
import { Activity } from "./DataRoom/Activity";
import { Questions } from "./questions";
import { Statements } from "./seller/statements";
import { Home } from "./home";
import { CreateDealFlow } from "./create_deal";
import { Question } from "./questions/Question";
import { Firm } from "./firm";
import { FirmInvite } from "./firm/Invite";
import { Settings } from "./settings";
import { DealProtection } from "./DealProtection";
import { Dashboard } from "./dashboard";
import { Errors } from "./errors";
import { ErrorDetails } from "./errors/Error";
import { DealSettings } from "./deal/settings";
import { Group } from "./questions/Group";
import { Buyers } from "./buyers";
import { Buyer } from "./buyers/buyer";
import { DDChecklists } from "./buyers/dd-checklists";
import { DDChecklist } from "./buyers/dd-checklists/DDChecklist";
import { OnboardingGate } from "./OnboardingGate";
import { AccountGate } from "./TokenGate";
import { ErrorChecking } from "./toolkit/error-checking";
import { DocumentDeepDive } from "./toolkit/deep-dive";
import { Ddql } from "./questions/ddql";
import { DeepSearch } from "./toolkit/deep-search";
import { Tasks } from "./tasks";
import { Task } from "./tasks/task";
import { Archived } from "./home/Archived";
import { FirmFiles } from "./firm/files";
import { FirmDataRoomFile } from "./firm/files/data_room/data_room_file";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import { DealNavigation } from "../components/DealNavigation";
import { Onboarding } from "./onboarding";

const Routes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute path="/login" onlyUnAuthenticated>
          <Login />
        </ProtectedRoute>
        <ProtectedRoute path="/signup" onlyUnAuthenticated>
          <Signup />
        </ProtectedRoute>

        <Route path="/deal-invite/:token">
          <Invite />
        </Route>

        <Route path="/firm-invite/:token">
          <FirmInvite />
        </Route>

        <OnboardingGate>
          <AccountGate>
            <ProtectedRoute path="/">
              <AuthedRoot />
            </ProtectedRoute>
          </AccountGate>
        </OnboardingGate>
      </Switch>
    </>
  );
};

const AuthedRoot = () => {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const location = useLocation();

  return (
    <div className="flex flex-1 flex-col md:flex-row bg-concrete-100 min-w-0">
      <Route path="/" exact>
        <Redirect to={"/home"} />
      </Route>

      {location.pathname.startsWith("/onboarding") ? null : <Navigation />}
      <div className=" bg-concrete-50 flex flex-col flex-1 min-w-0">
        {activeDealId &&
        !location.pathname.endsWith("/home") &&
        !location.pathname.includes("create-deal") ? (
          <DealNavigation />
        ) : null}
        <div className="  flex-1 flex  flex-col max-w-full min-w-0">
          <Route path="/onboarding">
            <Onboarding />
          </Route>
          <Route path="/home" exact>
            <Home />
          </Route>

          <Route path="/home/archived">
            <Archived />
          </Route>

          <Route path="/tasks/:id">
            <Task />
          </Route>

          <Route path="/tasks" exact>
            <Tasks />
          </Route>

          <Route path="/create-deal">
            <CreateDealFlow />
          </Route>

          <Route path="/firm/drive/folder/:folderId/file/:id" exact>
            <FirmDataRoomFile />
          </Route>

          <Route path="/firm/drive/folder/:folderId" exact>
            <FirmFiles />
          </Route>

          <Route path="/firm/drive" exact>
            <FirmFiles />
          </Route>

          <Route path="/transaction/documents/folder/:folderId/file/:id" exact>
            <FirmDataRoomFile root="transaction" />
          </Route>

          <Route path="/firm" exact>
            <Firm />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <RoutesRoot />
        </div>
      </div>
    </div>
  );
};

const RoutesRoot = () => {
  return (
    <DealProtection>
      <Switch>
        <Route path="/finances/statements">
          <Statements />
        </Route>

        <Route path="/finances">
          <Redirect to="/finances/statements" />
        </Route>

        <Route path="/dashboard">
          <Redirect to="/deal/dashboard" />
        </Route>

        <Route path="/deal/dashboard">
          <Dashboard />
        </Route>

        <Route path="/buyers/" exact>
          <Buyers />
        </Route>

        <Route path="/buyers/dd-checklists" exact>
          <DDChecklists />
        </Route>

        <Route path="/buyers/dd-checklists/:id">
          <DDChecklist />
        </Route>

        <Route path="/buyers/:id">
          <Buyer />
        </Route>

        <Route path="/deal/questions/lists/:id">
          <Ddql />
        </Route>

        <Route path="/deal/questions/group/:id">
          <Group />
        </Route>

        <Route path="/deal/questions/:id">
          <Question />
        </Route>

        <Route path="/deal/questions">
          <Questions />
        </Route>

        <Route path="/deal/toolkit/deep-search/:id">
          <DeepSearch />
        </Route>

        <Route path="/deal/toolkit/deep-search">
          <DeepSearch />
        </Route>

        <Route path="/deal/toolkit/error-checking/:id">
          <ErrorChecking />
        </Route>

        <Route path="/deal/toolkit/error-checking">
          <ErrorChecking />
        </Route>

        <Route path="/deal/toolkit/deep-dive/:fileId">
          <DocumentDeepDive />
        </Route>

        <Route path="/deal/toolkit/deep-dive">
          <DocumentDeepDive />
        </Route>

        <Route path="/deal/toolkit" exact>
          <Redirect to="/deal/toolkit/error-checking" />
        </Route>

        <Route path="/errors" exact>
          <Errors />
        </Route>

        <Route path="/errors/:id">
          <ErrorDetails />
        </Route>

        <Route path="/deal/documents/folder/:folderId/file/:id/versions">
          <DataRoomFile />
        </Route>
        <Route path="/deal/documents/folder/:folderId/file/:id" exact>
          <DataRoomFile />
        </Route>
        <Route path="/deal/documents/folder/:folderId">
          <DataRoom />
        </Route>

        <Route path="/deal/access">
          <Access />
        </Route>

        <Route path="/deal/documents/activity" exact>
          <Activity />
        </Route>

        <Route path="/deal/documents" exact>
          <DataRoom />
        </Route>

        <Route path="/deal/settings">
          <DealSettings />
        </Route>

        <Route path="/deal/documents">
          <Redirect to="/deal/documents" />
        </Route>
      </Switch>
    </DealProtection>
  );
};

export default Routes;

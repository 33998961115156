import { formatDistance, formatISO, secondsToMilliseconds } from "date-fns";
import { Avatar } from "../../components/account/Avatar";
import { ActivityItemMode } from "../../components/activity/ActivityItem";
import { TaskActivityType, TaskQuery } from "../../graphql/generated";
import { classNames } from "../../utils/cn";
import { Link } from "react-router-dom";
import { renderMentions } from "@/src/utils/renderMentions";

export function TaskActivityItem(props: {
  activity: TaskQuery["task"]["activity"][0];
  isLast: boolean;
  isFirst: boolean;
  mode?: ActivityItemMode;
}) {
  if (props.activity.type === TaskActivityType.Comment) {
    if (!props.activity.comment) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h-6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center"
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div className={props.isFirst ? "mt-0" : "mt-2"}>
            <Avatar account={props.activity.account} />
          </div>
          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white/50">
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                commented
                {props.mode === "verbose" ? (
                  <span>
                    {" "}
                    on <CommentNode activity={props.activity} />
                  </span>
                ) : null}
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt)
                )}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  }
                )}
              </time>
            </div>
            <p className="text-sm leading-6 text-gray-500">
              {renderMentions(props.activity.comment.comment)}
            </p>
          </div>
        </>
      </li>
    );
  }

  return null;
}

function CommentNode(props: { activity: TaskQuery["task"]["activity"][0] }) {
  if (props.activity.task) {
    return (
      <Link to={`/tasks/${props.activity.task.id}`}>
        <span className="font-semibold hover:text-gray-700">
          {props.activity.task.title}
        </span>
      </Link>
    );
  }

  return null;
}

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Tabs from "../../Tabs";
import { fileWrapper, transactionFileWrapper } from "./NewFilesUploader";
import { Button } from "../../tailwind/Button";
import {
  AllFoldersDocument,
  AllFoldersQuery,
  AllFoldersQueryVariables,
  CopyDataRoomFileDocument,
  CopyDataRoomFileMutation,
  CopyDataRoomFileMutationVariables,
  CreateDataRoomFileDocument,
  CreateDataRoomFileMutation,
  CreateDataRoomFileMutationVariables,
  DataRoomFile,
  DataRoomPermission,
  S3MultipartUploadCompleteDocument,
  S3MultipartUploadCompleteMutation,
  S3MultipartUploadCompleteMutationVariables,
  useAllFoldersQuery,
  useDealFilesQuery,
  useFirmFilesQuery
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import Dropdown, { Option } from "../../tailwind/Dropdown";
import { getNumberOfParts, Uploader } from "@/src/utils/multipartUploader";
import {
  CheckCircleIcon,
  DocumentPlusIcon,
  ExclamationCircleIcon,
  FolderIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "@/src/utils/cn";
import { LocalFileItem } from "./LocalFileItem";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import Loading from "../../Loading";
import { FileIcon } from "../../FileIcon";
import { TextInput } from "../../tailwind/TextInput";
import { useLocation } from "react-router-dom";
import { Spinner } from "../../icons/Spinner";
import { FileUpIcon, HardDriveIcon } from "lucide-react";

export function MultiStepUploader(props: {
  dataRoomFolderId: string;
  permissions: DataRoomPermission[];
  onDone: () => void;
}) {
  const [uploadFiles, setUploadFiles] = useState<fileWrapper[]>([]);
  const [transactionFiles, setTransactionFiles] = useState<
    transactionFileWrapper[]
  >([]);
  const location = useLocation();
  const account = useSelector(authSelectors.account);
  const activeDealId = useSelector(authSelectors.activeDealId);

  const [uploading, setUploading] = useState(false);

  const client = useGqlClient();

  const dealFilesQuery = useDealFilesQuery(client, {
    dealID: activeDealId ?? "",
  });

  const firmFilesQuery = useFirmFilesQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  },
  {
    enabled: Boolean(account?.firm?.id),
  });


  function copyTransactionFile(file: transactionFileWrapper): Promise<void> {
    if (!firmFilesQuery.data || !dealFilesQuery.data) {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }

    return new Promise((resolve, reject) => {
      try {
        setTransactionFiles((prev) => {
          return prev.map((td) => {
            if (td.file.id === file.file.id) {
              return {
                ...td,
                status: "copying",
              };
            }

            return td;
          });
        });

        client
          .request<CopyDataRoomFileMutation, CopyDataRoomFileMutationVariables>(
            CopyDataRoomFileDocument,
            {
              input: {
                dataRoomPermissionID: file.dataRoomPermissionId,
                fromDataRoomFileID: file.file.id,
                fromDataRoomID: firmFilesQuery.data.firm.dataRoom.id,
                name: file.name,
                toDataRoomFolderID: props.dataRoomFolderId,
                toDataRoomID: dealFilesQuery.data.deal.dataRoom.id,
              },
            }
          )
          .catch((err) => {
            console.log(err);
            setTransactionFiles((prev) => {
              return prev.map((td) => {
                if (td.file.id === file.file.id) {
                  return {
                    ...td,
                    status: "error",
                  };
                }

                return td;
              });
            });
          })
          .then((data) => {
            if (!data) {
              return;
            }

            setTransactionFiles((prev) => {
              return prev.map((td) => {
                if (td.file.id === file.file.id) {
                  return {
                    ...td,
                    status: "complete",
                  };
                }

                return td;
              });
            });
          });
      } catch (err) {
        console.log(err);
        setTransactionFiles((prev) => {
          return prev.map((td) => {
            if (td.file.id === file.file.id) {
              return {
                ...td,
                status: "error",
              };
            }

            return td;
          });
        });
      } finally {
        resolve();
      }
    });
  }

  function uploadFile(fileWrap: fileWrapper): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        setUploadFiles((prev) => {
          return prev.map((f) => {
            if (f.id === fileWrap.id) {
              return {
                ...f,
                status: "creating",
              };
            }

            return f;
          });
        });

        client
          .request<
            CreateDataRoomFileMutation,
            CreateDataRoomFileMutationVariables
          >(CreateDataRoomFileDocument, {
            name: fileWrap.name,
            description: "",
            dataRoomFolderID: props.dataRoomFolderId,
            dataRoomPermissionID: fileWrap.selectedPermission.value as string,
            fileName: fileWrap.file.name,
            fileType: fileWrap.file.type,
            parts: getNumberOfParts(fileWrap.file),
          })
          .catch((e) => {
            setUploadFiles((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "error",
                  };
                }

                return f;
              });
            });
          })
          .then((data) => {
            if (!data) {
              return;
            }

            setUploadFiles((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "uploading",
                  };
                }

                return f;
              });
            });

            const uploader = new Uploader({
              file: fileWrap.file,
              uploadId: data.createDataRoomFile.multiPartUploadID,
              parts: data.createDataRoomFile.parts.map((p) => {
                return {
                  PartNumber: p.partNumber,
                  signedUrl: p.presignedUrl,
                };
              }),
            });

            uploader.start();

            uploader.onError(() => {
              setUploadFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      status: "error",
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onProgress((progress) => {
              setUploadFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      uploadProgress: progress.percentage,
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onComplete((uploadedParts) => {
              client
                .request<
                  S3MultipartUploadCompleteMutation,
                  S3MultipartUploadCompleteMutationVariables
                >(S3MultipartUploadCompleteDocument, {
                  dataRoomFileID: data.createDataRoomFile.dataRoomFile.id,
                  dataRoomFileVersionID:
                    data.createDataRoomFile.initialVersion.id,
                  multiPartUploadID: data.createDataRoomFile.multiPartUploadID,
                  parts: uploadedParts.map((up) => {
                    return {
                      partNumber: up.PartNumber,
                      etag: up.ETag,
                    };
                  }),
                })
                .then((d) => {
                  if (!d) {
                    return;
                  }
                  setUploadFiles((prev) => {
                    return prev.map((f) => {
                      if (f.id === fileWrap.id) {
                        return {
                          ...f,
                          status: "complete",
                        };
                      }

                      return f;
                    });
                  });
                });
            });
          });
      } finally {
        resolve();
      }
    });
  }

  useEffect(() => {
    if (firmFilesQuery.data) {
      client
        .request<AllFoldersQuery, AllFoldersQueryVariables>(
          AllFoldersDocument,
          {
            folderId: firmFilesQuery.data.firm.dataRoom.folder.id,
          }
        )
        .then((data) => {
          const transactionFiles: transactionFileWrapper[] = [];

          for (const d of data.allFolders) {
            const files: transactionFileWrapper[] = d.files.map((f) => {
              return {
                id: f.id,
                name: f.name,
                file: f as DataRoomFile,
                isSelected: false,
                status: "pending",
                dataRoomPermissionId:
                  props.permissions.length > 0 ? props.permissions[0].id : "",
              };
            });

            transactionFiles.push(...files);
          }

          setTransactionFiles(transactionFiles);
        });
    }
  }, [firmFilesQuery.data]);

  function clearForm() {
    setUploadFiles([]);
    setTransactionFiles([]);
  }

  if (dealFilesQuery.isLoading, firmFilesQuery.isLoading || !dealFilesQuery.data) {
    return (
      <div className="h-80 flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (dealFilesQuery.error || firmFilesQuery.error) {
    return (
      <div className="h-80 flex items-center justify-center">
        <p className="text-gray-500">Something went wrong</p>
      </div>
    );
  }

  const tabs =
    location.pathname.startsWith("/deal/documents") &&
    firmFilesQuery.data!
      ? [
          {
            label: "Upload",
            icon: <FileUpIcon className="w-4 h-4" />,
            content: (
              <UploadTab
                dataRoomFolderId={props.dataRoomFolderId}
                permissions={props.permissions}
                files={uploadFiles}
                onFilesChange={setUploadFiles}
                uploading={uploading}
              />
            ),
            badge: uploadFiles.length.toString(),
          },
          {
            label: "Firm Drive",
            icon: <HardDriveIcon className="w-4 h-4" />,
            content: (
              <TransactionFilesTab
                uploading={uploading}
                dataRoomFolderId={props.dataRoomFolderId}
                permissions={props.permissions}
                files={transactionFiles}
                onFilesChange={setTransactionFiles}
              />
            ),
            badge: transactionFiles
              .filter((td) => td.isSelected)
              .length.toString(),
          },
        ]
      : [
          {
            label: "Upload",
            icon: <FileUpIcon className="w-4 h-4" />,
            content: (
              <UploadTab
                uploading={uploading}
                dataRoomFolderId={props.dataRoomFolderId}
                permissions={props.permissions}
                files={uploadFiles}
                onFilesChange={setUploadFiles}
              />
            ),
            badge: uploadFiles.length.toString(),
          },
        ];

  return (
    <div>
      <Tabs tabs={tabs} />
      <Footer
        disabled={
          uploadFiles.length === 0 &&
          transactionFiles.filter((td) => td.isSelected).length === 0
        }
        onDone={() => {
          props.onDone();
          clearForm();
        }}
        onRetry={() => {
          Promise.all([
            ...uploadFiles
              .filter((uf) => uf.status === "error")
              .map((uf) => uploadFile(uf)),
            ...transactionFiles
              .filter((td) => td.status === "error")
              .map((td) => copyTransactionFile(td)),
          ]);
        }}
        onSubmit={() => {
          setUploading(true);

          Promise.all([
            ...uploadFiles.map((f) => uploadFile(f)),
            ...transactionFiles
              .filter((td) => td.isSelected)
              .map((td) => copyTransactionFile(td)),
          ])
            .then(() => {
              // Add any additional logic you want to execute after all operations are complete
            })
            .catch((error) => {
              console.error("Error during file operations:", error);
              // Handle any errors that occurred during the process
            });
        }}
        uploadFiles={uploadFiles}
        transactionFiles={transactionFiles}
      />
    </div>
  );
}

export function SingleTabUploader(props: {
  dataRoomFolderId: string;
  permissions: DataRoomPermission[];
  onDone: () => void;
}) {
  const [uploadFiles, setUploadFiles] = useState<fileWrapper[]>([]);

  const location = useLocation();
  const account = useSelector(authSelectors.account);
  const [uploading, setUploading] = useState(false);

  const client = useGqlClient();

  function uploadFile(fileWrap: fileWrapper): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        setUploadFiles((prev) => {
          return prev.map((f) => {
            if (f.id === fileWrap.id) {
              return {
                ...f,
                status: "creating",
              };
            }

            return f;
          });
        });

        client
          .request<
            CreateDataRoomFileMutation,
            CreateDataRoomFileMutationVariables
          >(CreateDataRoomFileDocument, {
            name: fileWrap.name,
            description: "",
            dataRoomFolderID: props.dataRoomFolderId,
            dataRoomPermissionID: fileWrap.selectedPermission.value as string,
            fileName: fileWrap.file.name,
            fileType: fileWrap.file.type,
            parts: getNumberOfParts(fileWrap.file),
          })
          .catch((e) => {
            setUploadFiles((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "error",
                  };
                }

                return f;
              });
            });
          })
          .then((data) => {
            if (!data) {
              return;
            }

            setUploadFiles((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "uploading",
                  };
                }

                return f;
              });
            });

            const uploader = new Uploader({
              file: fileWrap.file,
              uploadId: data.createDataRoomFile.multiPartUploadID,
              parts: data.createDataRoomFile.parts.map((p) => {
                return {
                  PartNumber: p.partNumber,
                  signedUrl: p.presignedUrl,
                };
              }),
            });

            uploader.start();

            uploader.onError(() => {
              setUploadFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      status: "error",
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onProgress((progress) => {
              setUploadFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      uploadProgress: progress.percentage,
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onComplete((uploadedParts) => {
              client
                .request<
                  S3MultipartUploadCompleteMutation,
                  S3MultipartUploadCompleteMutationVariables
                >(S3MultipartUploadCompleteDocument, {
                  dataRoomFileID: data.createDataRoomFile.dataRoomFile.id,
                  dataRoomFileVersionID:
                    data.createDataRoomFile.initialVersion.id,
                  multiPartUploadID: data.createDataRoomFile.multiPartUploadID,
                  parts: uploadedParts.map((up) => {
                    return {
                      partNumber: up.PartNumber,
                      etag: up.ETag,
                    };
                  }),
                })
                .then((d) => {
                  if (!d) {
                    return;
                  }
                  setUploadFiles((prev) => {
                    return prev.map((f) => {
                      if (f.id === fileWrap.id) {
                        return {
                          ...f,
                          status: "complete",
                        };
                      }

                      return f;
                    });
                  });
                });
            });
          });
      } finally {
        resolve();
      }
    });
  }

  function clearForm() {
    setUploadFiles([]);
  }

  const tabs = [
    {
      label: "Upload",
      icon: <FileUpIcon className="w-4 h-4" />,
      content: (
        <UploadTab
          uploading={uploading}
          dataRoomFolderId={props.dataRoomFolderId}
          permissions={props.permissions}
          files={uploadFiles}
          onFilesChange={setUploadFiles}
        />
      ),
      badge: uploadFiles.length.toString(),
    },
  ];

  return (
    <div>
      <Tabs tabs={tabs} />
      <Footer
        disabled={uploadFiles.length === 0}
        onDone={() => {
          props.onDone();
          clearForm();
        }}
        onRetry={() => {
          Promise.all([
            ...uploadFiles
              .filter((uf) => uf.status === "error")
              .map((uf) => uploadFile(uf)),
          ]);
        }}
        onSubmit={() => {
          setUploading(true);

          Promise.all([...uploadFiles.map((f) => uploadFile(f))])
            .then(() => {
              // Add any additional logic you want to execute after all operations are complete
            })
            .catch((error) => {
              console.error("Error during file operations:", error);
              // Handle any errors that occurred during the process
            });
        }}
        uploadFiles={uploadFiles}
        transactionFiles={[]}
      />
    </div>
  );
}

interface TabProps {
  uploading: boolean;
  onFilesChange: Dispatch<SetStateAction<fileWrapper[]>>;
  files: fileWrapper[];
  dataRoomFolderId: string;
  permissions: DataRoomPermission[];
}

function UploadTab(props: TabProps) {
  const [dragActive, setDragActive] = useState(false);

  const [description, setDescription] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState("");

  const client = useGqlClient();

  const defaultPermission =
    props.permissions.length > 0 ? props.permissions[0] : null;
  const [selectedPermission, setSelectedPermission] = useState<Option>({
    label: defaultPermission ? defaultPermission.name : "",
    value: defaultPermission ? defaultPermission.id : "",
    description: defaultPermission ? defaultPermission.description : "",
  });

  const handleDrag = function (e: any) {
    setFileError("");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files: fileWrapper[] = Array.from(
        e.dataTransfer.files as File[]
      ).map((f: File, i) => {
        // remove extension from file name
        const name = f.name.replace(/\.[^/.]+$/, "");
        return {
          id: i,
          file: f,
          name,
          dataRoomPermissionId: defaultPermission ? defaultPermission.id : "",
          uploadProgress: 0,
          status: "pending",
          selectedPermission: {
            value: defaultPermission ? defaultPermission.id : "",
            label: defaultPermission ? defaultPermission.name : "",
            description: defaultPermission ? defaultPermission.description : "",
          },
        };
      });

      props.onFilesChange(files);
    }
  };

  function uploadFile(fileWrap: fileWrapper): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        props.onFilesChange((prev) => {
          return prev.map((f) => {
            if (f.id === fileWrap.id) {
              return {
                ...f,
                status: "creating",
              };
            }

            return f;
          });
        });

        client
          .request<
            CreateDataRoomFileMutation,
            CreateDataRoomFileMutationVariables
          >(CreateDataRoomFileDocument, {
            name: fileWrap.name,
            description,
            dataRoomFolderID: props.dataRoomFolderId,
            dataRoomPermissionID: fileWrap.selectedPermission.value as string,
            fileName: fileWrap.file.name,
            fileType: fileWrap.file.type,
            parts: getNumberOfParts(fileWrap.file),
          })
          .catch((e) => {
            props.onFilesChange((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "error",
                  };
                }

                return f;
              });
            });
          })
          .then((data) => {
            if (!data) {
              return;
            }

            props.onFilesChange((prev) => {
              return prev.map((f) => {
                if (f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "uploading",
                  };
                }

                return f;
              });
            });

            const uploader = new Uploader({
              file: fileWrap.file,
              uploadId: data.createDataRoomFile.multiPartUploadID,
              parts: data.createDataRoomFile.parts.map((p) => {
                return {
                  PartNumber: p.partNumber,
                  signedUrl: p.presignedUrl,
                };
              }),
            });

            uploader.start();

            uploader.onError(() => {
              props.onFilesChange((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      status: "error",
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onProgress((progress) => {
              props.onFilesChange((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      uploadProgress: progress.percentage,
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onComplete((uploadedParts) => {
              client
                .request<
                  S3MultipartUploadCompleteMutation,
                  S3MultipartUploadCompleteMutationVariables
                >(S3MultipartUploadCompleteDocument, {
                  dataRoomFileID: data.createDataRoomFile.dataRoomFile.id,
                  dataRoomFileVersionID:
                    data.createDataRoomFile.initialVersion.id,
                  multiPartUploadID: data.createDataRoomFile.multiPartUploadID,
                  parts: uploadedParts.map((up) => {
                    return {
                      partNumber: up.PartNumber,
                      etag: up.ETag,
                    };
                  }),
                })
                .then((d) => {
                  if (!d) {
                    return;
                  }
                  props.onFilesChange((prev) => {
                    return prev.map((f) => {
                      if (f.id === fileWrap.id) {
                        return {
                          ...f,
                          status: "complete",
                        };
                      }

                      return f;
                    });
                  });
                });
            });
          });
      } finally {
        resolve();
      }
    });
  }

  if (props.files.length > 0) {
    return (
      <div
        className="h-80 overflow-y-auto scrollbar-thin"
        key={props.files.length}
      >
        <div key={props.files.length}>
          {props.files
            .sort((a, b) => {
              if (a.status === "complete" && b.status !== "complete") {
                return 1;
              }

              if (a.status !== "complete" && b.status === "complete") {
                return -1;
              }

              return 0;
            })
            .map((file, i) => {
              return (
                <LocalFileItem
                  key={i}
                  file={file}
                  onFilesChange={props.onFilesChange}
                  permissions={props.permissions}
                  uploadFile={uploadFile}
                />
              );
            })}

          <div>
            {!props.uploading ? (
              <button
                type="button"
                className="px-3 mt-2 inline-flex items-center gap-x-1 bg-white text-blue-500 hover:text-blue-600  rounded-full font-semibold text-sm"
                onClick={() => {
                  setFileError("");
                  if (inputRef.current) {
                    inputRef.current.click();
                  }
                }}
              >
                <PlusIcon className="w-5 h-5" />
                <p>Add more files</p>
              </button>
            ) : null}
            <input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  const f: fileWrapper[] = Array.from(e.target.files).map(
                    (f, i) => {
                      // remove extension from file name
                      const name = f.name.replace(/\.[^/.]+$/, "");
                      return {
                        id: props.files.length + 1,
                        file: f,
                        name,
                        dataRoomPermissionId: selectedPermission.value
                          ? selectedPermission.value
                          : defaultPermission
                          ? defaultPermission.id
                          : "",
                        uploadProgress: 0,
                        status: "pending",
                        selectedPermission: {
                          value: defaultPermission ? defaultPermission.id : "",
                          label: defaultPermission
                            ? defaultPermission.name
                            : "",
                          description: defaultPermission
                            ? defaultPermission.description
                            : "",
                        },
                      };
                    }
                  );

                  props.onFilesChange((prevFiles) => {
                    return [...prevFiles, ...f];
                  });
                }
              }}
              ref={inputRef}
              type="file"
              multiple
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-3 h-80">
      <div className="h-full flex flex-col">
        <div
          className={classNames(
            "p-2 flex-1 gap-x-2 bg-gray-50 border-2 transition-all duration-300 ease-in-out rounded-md border-dashed border-gray-300 flex flex-col items-center justify-center",
            dragActive ? "border-indigo-500 bg-indigo-100/80 shadow" : "",
            fileError ? "border-red-500" : ""
          )}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <p className="text-sm mb-1 text-gray-500 font-medium">
            Drop new files here or
          </p>
          <div className="flex justify-center">
            <button
              type="button"
              className="inline-flex items-center gap-x-1 bg-white text-blue-500 border px-2 py-1 rounded-full font-semibold hover:border-blue-400 hover:shadow text-sm"
              onClick={() => {
                setFileError("");
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
            >
              <DocumentPlusIcon className="w-5 h-5" />
              <p>Upload</p>
            </button>
            <input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  const f: fileWrapper[] = Array.from(e.target.files).map(
                    (f, i) => {
                      // remove extension from file name
                      const name = f.name.replace(/\.[^/.]+$/, "");
                      return {
                        id: i,
                        file: f,
                        name,
                        dataRoomPermissionId:
                          selectedPermission.value ??
                          defaultPermission?.id ??
                          "",
                        uploadProgress: 0,
                        status: "pending",
                        selectedPermission: {
                          value: defaultPermission?.id ?? "",
                          label: defaultPermission?.name ?? "",
                          description: defaultPermission?.description ?? "",
                        },
                      };
                    }
                  );

                  props.onFilesChange(f);
                }
              }}
              ref={inputRef}
              type="file"
              multiple
              style={{ display: "none" }}
            />
          </div>
        </div>

        {fileError ? (
          <p className="text-red-500 font-medium mt-2 text-sm">{fileError}</p>
        ) : null}
      </div>
    </div>
  );
}

interface TransactionTabProps {
  onFilesChange: Dispatch<SetStateAction<transactionFileWrapper[]>>;
  files: transactionFileWrapper[];
  dataRoomFolderId: string;
  permissions: DataRoomPermission[];
  uploading: boolean;
}

function TransactionFilesTab(props: TransactionTabProps) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const account = useSelector(authSelectors.account);

  const client = useGqlClient();

  const firmFilesQuery = useFirmFilesQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  },
  {
    enabled: Boolean(account?.firm?.id),
  });


  if (firmFilesQuery.isLoading || !firmFilesQuery.data) {
    return <Loading />;
  }

  if (firmFilesQuery.error) {
    return <p>Error loading Transaction documents</p>;
  }

  return (
    <div className="h-80 overflow-y-auto flex flex-col">
      <TransactionFilesTabInner
        {...props}
        folderId={firmFilesQuery.data.firm.dataRoom.folder.id}
      />
    </div>
  );
}

function TransactionFilesTabInner(
  props: TransactionTabProps & { folderId: string }
) {
  const client = useGqlClient();
  const allFolders = useAllFoldersQuery(client, {
    folderId: props.folderId,
  });

  const [searchTerm, setSearchTerm] = useState("");

  if (allFolders.isLoading || !allFolders.data) {
    return <Loading />;
  }

  if (allFolders.error) {
    return <p>Error loading Transaction documents</p>;
  }

  return (
    <div className="flex-1 flex flex-col">
      {!props.uploading ? (
        <div className="w-full">
          <input
            type="text"
            className="px-3 py-1.5 text-sm focus:ring-0 border-b border-x-0 border-t-0 border-gray-200 focus:border-gray-200"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
      ) : null}

      <div className="flex-1 overflow-y-auto ">
        {/* search input */}

        {allFolders.data.allFolders.map((f) => {
          return (
            <TransactionFilesTabFolderItem
              {...props}
              folder={f}
              searchTerm={searchTerm}
            />
          );
        })}
      </div>
    </div>
  );
}

function TransactionFilesTabFolderItem(
  props: TransactionTabProps & {
    folder: AllFoldersQuery["allFolders"][0];
    searchTerm: string;
  }
) {
  if (props.searchTerm.length > 2) {
    if (
      props.files.filter((fw) =>
        fw.file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
      ).length === 0
    ) {
      return null;
    }
  }

  if (
    props.uploading &&
    props.files.filter((fw) => fw.isSelected).length === 0
  ) {
    return null;
  }

  if (props.folder.files.length === 0) {
    return null;
  }

  return (
    <div className="">
      <div
        className={classNames(
          "items-center  hover:bg-gray-200/70 px-2 flex py-1 justify-between cursor-pointer"
        )}
      >
        <div className="flex py-1.5 px-0.5 items-center">
          <FolderIcon className="w-5 h-5 text-blue-700/70" />
          <p className="font-semibold mt-1 ml-1 select-none text-gray-800 text-sm">
            {props.folder.parentFolders.length > 0
              ? `${props.folder.parentFolders
                  .filter((f) => !!f.name)
                  .map((f) => (f.name ? f.name : "Home"))
                  .reverse()
                  .join(" / ")}`
              : "Home /"}
          </p>
        </div>
      </div>
      <div>
        {props.folder.files.map((f, i) => {
          const transactionFileWrap = props.files.filter(
            (fw) => fw.file.id === f.id
          )[0];

          if (props.searchTerm.length > 2) {
            if (
              !f.name.toLowerCase().includes(props.searchTerm.toLowerCase())
            ) {
              return null;
            }
          }

          if (!transactionFileWrap.isSelected && props.uploading) {
            return null;
          }

          return (
            <div
              key={f.id}
              className={`cursor-pointer px-2 justify-between hover:bg-gray-100/70 
                   ${i === 0 ? "border-y" : "border-b"} border-gray-200
                   items-center flex flex-row py-3 ${
                     !transactionFileWrap.isSelected
                       ? "opacity-50 hover:opacity-100"
                       : ""
                   }`}
              onClick={() => {
                props.onFilesChange((prev) => {
                  return prev.map((file) => {
                    if (file.file.id === f.id) {
                      return {
                        ...file,
                        isSelected: !file.isSelected,
                      };
                    }
                    return file;
                  });
                });
              }}
            >
              <div className="ml-2 flex gap-x-2 items-center">
                {!props.uploading ? (
                  <input
                    type="checkbox"
                    className="rounded-md border"
                    checked={transactionFileWrap.isSelected}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={() => {
                      props.onFilesChange((prev) => {
                        return prev.map((file) => {
                          if (file.file.id === f.id) {
                            return {
                              ...file,
                              isSelected: !file.isSelected,
                            };
                          }
                          return file;
                        });
                      });
                    }}
                  />
                ) : null}

                <FileIcon fileType={f.fileType} />
                <div className="w-80">
                  {!props.uploading ? (
                    <TextInput
                      value={transactionFileWrap.name}
                      inputSize="s"
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        props.onFilesChange((prev) => {
                          return prev.map((file) => {
                            if (file.file.id === f.id) {
                              return {
                                ...file,
                                name: value,
                              };
                            }
                            return file;
                          });
                        });
                      }}
                    />
                  ) : (
                    <p className="font-semibold text-sm text-gray-700 truncate">
                      {transactionFileWrap.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-x-2 items-center ">
                {!props.uploading ? (
                  <Dropdown
                    options={props.permissions.map((p) => ({
                      label: p.name,
                      value: p.id,
                      description: p.description,
                    }))}
                    variant="light"
                    size="s"
                    selectedOption={{
                      label: transactionFileWrap.dataRoomPermissionId
                        ? props.permissions.find(
                            (p) =>
                              p.id === transactionFileWrap.dataRoomPermissionId
                          )?.name || ""
                        : "",
                      value: transactionFileWrap.dataRoomPermissionId || "",
                    }}
                    onSelect={(option: Option) => {
                      props.onFilesChange((prev) => {
                        return prev.map((file) => {
                          if (file.file.id === f.id) {
                            return {
                              ...file,
                              dataRoomPermissionId: option.value as string,
                            };
                          }
                          return file;
                        });
                      });
                    }}
                  />
                ) : null}
                <TransactionFileItemStatus
                  status={transactionFileWrap.status}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function TransactionFileItemStatus(props: {
  status: "pending" | "copying" | "complete" | "error";
}) {
  if (props.status === "pending") {
    return null;
  }

  if (props.status === "copying") {
    return (
      <div>
        <Spinner color="gray" size="s" />
      </div>
    );
  }

  if (props.status === "complete") {
    return (
      <div>
        <CheckCircleIcon className="w-5 h-5 text-green-500" />
      </div>
    );
  }

  if (props.status === "error") {
    return (
      <div>
        <ExclamationCircleIcon className="w-5 h-5 text-orange-500" />
      </div>
    );
  }

  return null;
}

function Footer(props: {
  onSubmit: () => void;
  onDone: () => void;
  onRetry: () => void;
  disabled: boolean;
  uploadFiles: fileWrapper[];
  transactionFiles: transactionFileWrapper[];
}) {
  return (
    <div className="py-3 items-center justify-between flex px-3 border-t border-gray-200">
      <div className="flex items-center gap-x-2"></div>
      <div className="flex gap-x-2">
        {props.uploadFiles.some((uf) => uf.status === "error") ||
        props.transactionFiles.some((tf) => tf.status === "error") ? (
          <Button
            variant="neutral"
            text="Retry failed files"
            onClick={props.onRetry}
          />
        ) : null}
        <Button
          variant="positive"
          text={`${
            props.uploadFiles.some((uf) => uf.status === "complete") ||
            props.transactionFiles.some((tf) => tf.status === "complete")
              ? "Done"
              : "Create files"
          }`}
          loadingText="Creating files..."
          isLoading={
            props.uploadFiles.some((uf) => uf.status === "uploading") ||
            props.transactionFiles.some((tf) => tf.status === "copying")
          }
          isDisabled={props.disabled}
          onClick={() => {
            if (props.disabled) {
              return;
            }

            if (
              props.uploadFiles.some((uf) => uf.status === "uploading") ||
              props.transactionFiles.some((tf) => tf.status === "copying")
            ) {
              return;
            }

            if (
              props.uploadFiles.some((uf) => uf.status === "complete") ||
              props.transactionFiles.some((tf) => tf.status === "complete")
            ) {
              props.onDone();
              return;
            }

            props.onSubmit();
          }}
        />
      </div>
    </div>
  );
}

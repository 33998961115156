import { DocumentIcon } from "@heroicons/react/24/solid";
import { FileType } from "../graphql/generated";
import { useTheme } from "../hooks/useTheme";
import { DocumentChartBarIcon } from "@heroicons/react/24/solid";

interface FileIconProps {
  fileType: FileType;
  size?: "s" | "m" | "l";
}

const sizes = {
  s: 15,
  m: 20,
  l: 30,
};

export function FileIcon(props: FileIconProps) {
  const theme = useTheme();

  switch (props.fileType) {
    case FileType.Pdf:
      return (
        <DocumentIcon
          className="text-red-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Xlsx:
      return (
        <DocumentIcon
          className="text-green-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Docx:
      return (
        <DocumentIcon
          className="text-blue-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Pptx:
      return (
        <DocumentChartBarIcon
          className="text-pink-600/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    default:
      return (
        <DocumentIcon
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
          fill={theme.color.typography.secondary}
        />
      );
  }
}

import { NotificationType } from "@/src/graphql/generated";

import { Notification } from "@/src/graphql/generated";
import {
  ArrowRightIcon,
  CircleAlertIcon,
  ListIcon,
  MessageCircleIcon,
} from "lucide-react";

export function NotificationIcon(props: { notification: Notification }) {
  const notification = props.notification;
  let icon = null;
  let secondaryIcon = null;
  let secondaryIconBgColor = "bg-blue-700";

  switch (notification.type) {
    case NotificationType.TaskUrgent:
      icon = <ListIcon className="w-4 h-4 text-gray-500" />;
      secondaryIcon = <CircleAlertIcon className="w-2.5 h-2.5 text-white" />;
      secondaryIconBgColor = "bg-orange-700";
      break;
    case NotificationType.Comment:
      if (notification.task) {
        icon = <ListIcon className="w-4 h-4 text-gray-500" />;
        secondaryIcon = (
          <MessageCircleIcon className="w-2.5 h-2.5 text-white" />
        );
      } else {
        icon = <MessageCircleIcon className="w-4 h-4 text-white" />;
      }
      break;
    case NotificationType.TaskAssigned:
      icon = <ListIcon className="w-4 h-4 text-gray-500" />;
      secondaryIcon = <ArrowRightIcon className="w-2.5 h-2.5 text-white" />;
      secondaryIconBgColor = "bg-green-700";
      break;
  }

  if (!icon && !secondaryIcon) {
    return null;
  }

  return (
    <div className="bg-gray-200 relative p-2 rounded-full flex items-center justify-center">
      {icon}
      <div
        className={`absolute -top-1 -right-1 ${secondaryIconBgColor} p-0.5 rounded-full`}
      >
        {secondaryIcon}
      </div>
    </div>
  );
}

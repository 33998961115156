import { PdfViewerHandle } from "@/src/components/PdfViewer";
import React, { createContext, useContext, useRef } from "react";

type DocumentRefs = {
  [docId: string]: PdfViewerHandle; // Replace 'any' with proper PSPDFKit ref type
};

type DocumentRefsContextType = {
  refs: DocumentRefs;
  setRef: (docId: string, ref: any) => void;
};

const DocumentRefsContext = createContext<DocumentRefsContextType | undefined>(
  undefined
);

export function DocumentRefsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const refsMap = useRef<DocumentRefs>({});

  const setRef = (docId: string, ref: any) => {
    refsMap.current[docId] = ref;
  };

  return (
    <DocumentRefsContext.Provider value={{ refs: refsMap.current, setRef }}>
      {children}
    </DocumentRefsContext.Provider>
  );
}

export const useDocumentRefs = () => {
  const context = useContext(DocumentRefsContext);
  if (!context) {
    throw new Error(
      "useDocumentRefs must be used within a DocumentRefsProvider"
    );
  }
  return context;
};

import { useState } from "react";
import {
  DataRoomFile,
  useAllDataRoomFoldersQuery,
  AllDataRoomFoldersQuery,
} from "../graphql/generated";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import useGqlClient from "../hooks/useGqlClient";
import Loading from "./Loading";
import { FileIcon } from "./FileIcon";
import { AnimatedModal } from "./AnimatedModal";
import { CloseIcon } from "./CloseIcon";

export function AddFileModal(props: {
  onFileSelected: (
    file: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0]["files"][0]
  ) => void;
  open: boolean;
  onClose: () => void;
}) {
  const [query, setQuery] = useState("");

  return (
    <>
      <AnimatedModal
        padding="p-0"
        onClose={props.onClose}
        open={props.open}
        size="md"
      >
        <div className="min-h-80">
          <div className="border-b flex justify-between items-center p-3">
            <p className=" font-semibold text-gray-700">Add document</p>
            <CloseIcon onClose={props.onClose} />
          </div>
          <SearchAndSelect
            query={query}
            setQuery={setQuery}
            onFileSelected={(file) => {
              props.onFileSelected(file);
              props.onClose();
            }}
          />
        </div>
      </AnimatedModal>
    </>
  );
}

// Inline or import this component to perform the actual file searching:
function SearchAndSelect({
  query,
  setQuery,
  onFileSelected,
}: {
  query: string;
  setQuery: (val: string) => void;
  onFileSelected: (file: DataRoomFile) => void;
}) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const allFolders = useAllDataRoomFoldersQuery(client, {
    dealId: activeDealId ?? "",
  });

  if (allFolders.isError) return <div>Error loading folders</div>;
  if (allFolders.isPending || !allFolders.data) return <Loading />;

  return (
    <div>
      <div className="relative w-full cursor-default overflow-hidden rounded-t-md bg-white text-left border-b border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
        <input
          placeholder="Search files..."
          autoFocus
          className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
          onChange={(event) => setQuery(event.currentTarget.value)}
        />
      </div>
      <div className="mt-2 max-h-80 overflow-y-auto">
        {allFolders.data.deal.dataRoom.allFolders.map((folder) => (
          <FolderContent
            key={folder.id}
            folder={folder}
            searchTerm={query}
            onFileSelected={onFileSelected}
          />
        ))}
      </div>
    </div>
  );
}

function FolderContent(props: {
  folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  searchTerm: string;
  onFileSelected: (file: DataRoomFile) => void;
}) {
  const { folder, searchTerm, onFileSelected } = props;
  const filteredFiles = folder.files.filter((file) =>
    searchTerm.length > 2
      ? file.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  if (filteredFiles.length === 0) return null;

  return (
    <div className="mt-2">
      <p className="text-xs px-3 py-1 text-ellipsis">
        {folder.parentFolders.length > 0
          ? folder.parentFolders
              .map((f) => (f.name ? f.name : "Home"))
              .reverse()
              .join(" / ")
          : "Home /"}
      </p>
      {filteredFiles.map((file) => (
        <div
          key={file.id}
          className="cursor-pointer px-3 py-2 hover:bg-gray-200"
          onClick={() => onFileSelected(file as DataRoomFile)}
        >
          <div className="flex items-center gap-x-1.5">
            <FileIcon fileType={file.fileType} />
            <p className="text-sm font-semibold text-gray-800 leading-tight">
              {file.name}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

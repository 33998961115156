export function hashReference(reference: {
  pageIndex: number;
  quote: string;
  rectsOnPage: number[][];
}) {
  return `${reference.pageIndex}-${
    reference.quote
  }-${reference.rectsOnPage.join("-")}`;
}

export function hashRectsOnPage(rectsOnPage: number[][]) {
  return rectsOnPage.join("-");
}
